import { ComponentWrap } from '@/components/ComponentWrap'
import React, { useMemo } from 'react'
import { ImageGridComponent } from '@/types/cmsGenerated'
import ImageGrid from '@/components/ImageGrid'

export default function Component({ settings }: ImageGridComponent) {
    const { image1, image2, image3, image4, image5, image6 } = settings

    return (
        <ComponentWrap>
            <ImageGrid
                imageUrl1={image1?.path}
                imageAlt1={image1?.title}
                imageUrl2={image2?.path}
                imageAlt2={image2?.title}
                imageUrl3={image3?.path}
                imageAlt3={image3?.title}
                imageUrl4={image4?.path}
                imageAlt4={image4?.title}
                imageUrl5={image5?.path}
                imageAlt5={image5?.title}
                imageUrl6={image6?.path}
                imageAlt6={image6?.title}
            />
        </ComponentWrap>
    )
}
