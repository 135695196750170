import ReferenceBox from '@/components/ReferenceBox'
import { Reference_boxComponent } from '@/types/cmsGenerated'
import { renderLayout } from '@/cms_components'

export default function Component({
    settings,
    componentContext,
}: Reference_boxComponent) {
    const { layout, logos, title, subtitle } = settings

    return (
        <ReferenceBox
            itemCount={layout?.length || 0}
            logos={renderLayout(logos, {
                ...componentContext,
            })}
            title={title}
            subtitle={subtitle}
        >
            {renderLayout(layout, {
                ...componentContext,
            })}
        </ReferenceBox>
    )
}
