import { ComponentWrap } from '@/components/ComponentWrap'
import { IconButtonComponent } from '@/types/cmsGenerated'
import React from 'react'
import { IconButton } from '@/components/Button/IconButton'

export default function Component({ settings }: IconButtonComponent) {
    const { icon, url } = settings

    return (
        <ComponentWrap>
            <IconButton
                imageData={{
                    path: icon?.path as string,
                    alt: icon?.title, //TODO: there should be "icon?.description". Update it when the bug on BE is fixed, where the "description" property is not written
                }}
                url={url}
            />
        </ComponentWrap>
    )
}
