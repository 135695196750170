import clsx from 'clsx'

type Step = {
    key: string
    name: string
    active: boolean
}

type Props = {
    steps: Step[]
}

export const Stepper = ({ steps }: Props) => (
    <div className="w-full overflow-x-auto">
        <ol className="border-neutral flex justify-evenly items-stretch gap-6 border-b mb-4 min-w-fit">
            {steps.map((step) => (
                <li key={step.key}>
                    <div className="flex flex-col items-center pt-2 pb-0">
                        <div
                            className={clsx('mb-2 body3 whitespace-nowrap', {
                                'text-blue-middle': !step.active,
                                'text-blue-dark text-md font-bold': step.active,
                            })}
                        >
                            {step.name}
                        </div>
                        <div
                            className={clsx(
                                'h-[15px] w-[15px] rounded-full -mb-[9px] mr-0',
                                {
                                    'bg-neutral': !step.active,
                                    'bg-primary': step.active,
                                }
                            )}
                        />
                    </div>
                </li>
            ))}
        </ol>
        {/*<div className="border-neutral border-b min-w-full mb-2" />*/}
    </div>
)
