'use client'

import { AccordionComponent } from '@/types/cmsGenerated'
import Accordion, { Props as AccordionProps } from '@/components/Accordion'
import { renderLayout } from '@/cms_components'
import { useMemo } from 'react'

export default function Component({
    settings,
    componentContext,
}: AccordionComponent) {
    const { items, singleOpen, id } = settings

    const renderItems: AccordionProps['items'] = useMemo(
        () =>
            items.map((item) => ({
                label: item.value.label,
                body: renderLayout(item.value.body, componentContext),
            })),
        [items, componentContext]
    )

    if (!items || !items.length) return null

    return (
        <div>
            <Accordion
                items={renderItems}
                singleOpen={singleOpen}
                id={id || 'accordion'}
            />
        </div>
    )
}
