import { ComponentWrap } from '@/components/ComponentWrap'
import React, { useMemo } from 'react'
import { Image_stripComponent } from '@/types/cmsGenerated'
import ImageStrip, { ImageType } from '@/components/ImageStrip'

export default function Component({ settings }: Image_stripComponent) {
    const { id, image_1, image_2, image_3, image_4 } = settings

    const images = useMemo(
        () =>
            [image_1, image_2, image_3, image_4]
                .map((img) => ({
                    src: img?.path,
                    alt: img?.title,
                }))
                .filter((i) => !!i.src) as ImageType[],
        [image_1, image_2, image_3, image_4]
    )

    return (
        <ComponentWrap>
            <ImageStrip id={id} images={images} />
        </ComponentWrap>
    )
}
