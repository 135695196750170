import { Heading } from '@/components/Heading'
import { createElement } from 'react'
import clsx from 'clsx'

type Cell = {
    value: string | number | undefined
    tag?: keyof JSX.IntrinsicElements
    classes?: string
}

type Row = Cell[]

export type TableData = Row[]

type Props = {
    title?: string
    rootClasses?: string
    tableData: TableData
}

const DEFAULT_TAG = 'p'

export const SimpleTable = ({ title, rootClasses, tableData }: Props) => {
    return (
        <>
            {title && (
                <div className="mt-16 flex justify-center">
                    <Heading className="uppercase" text={title} tag="h5" />
                </div>
            )}
            <table className={clsx('table mt-8 mx-auto', rootClasses)}>
                <tbody>
                    {tableData.map((row, rowIndex) => (
                        <tr
                            key={rowIndex}
                            className="border-b border-gray-light2"
                        >
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex} className={cell.classes}>
                                    {createElement(
                                        cell.tag || DEFAULT_TAG,
                                        {},
                                        cell.value
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}
