'use client'
import { useState, useMemo, useCallback, ReactNode } from 'react'
import clsx from 'clsx'

export type Tab = {
    key: string
    label: string | ReactNode
    content: JSX.Element | null
}

type Props = {
    tabs: Tab[]
    defaultActive?: Tab['key']
    onTabChange?: (tabKey: Tab['key']) => void
    fullWidthContent?: boolean
    align?: 'left' | 'center' | 'right'
    hideBorder?: boolean
}

export const Tabs = ({
    tabs,
    defaultActive,
    onTabChange,
    fullWidthContent,
    align = 'center',
    hideBorder,
}: Props) => {
    const [activeKey, setActiveKey] = useState<string>(
        defaultActive || tabs[0].key
    )
    const tabClick = useCallback(
        (key: string) => () => {
            setActiveKey(key)
            onTabChange?.(key)
        },
        [onTabChange]
    )
    const content = useMemo(
        () => tabs.find((tab) => tab.key === activeKey)?.content,
        [tabs, activeKey]
    )

    return (
        <div className="w-full md:max-w-screen-xl md:mx-auto">
            <div className="flex flex-col w-full items-center">
                <div
                    className={clsx(
                        'tabs w-full border-neutral flex',
                        align === 'center'
                            ? 'justify-center'
                            : align === 'right'
                            ? 'justify-end'
                            : 'justify-start',
                        hideBorder ? 'border-0' : 'border-b'
                    )}
                >
                    {tabs.map((tab) => (
                        <div
                            key={tab.key}
                            onClick={tabClick(tab.key)}
                            className={clsx('tab text-md pb-12', {
                                'text-blue-dark border-b-4 border-primary opacity-100':
                                    tab.key === activeKey,
                                'text-blue-middle mb-[4px] opacity-60':
                                    tab.key !== activeKey,
                            })}
                        >
                            {tab.label}
                        </div>
                    ))}
                </div>
                <div
                    className={clsx(
                        'my-8 w-full',
                        fullWidthContent && 'flex-grow w-full'
                    )}
                >
                    {content}
                </div>
            </div>
        </div>
    )
}
