import StatsCounter from '@/components/StatsCounter'
import { ComponentWrap } from '@/components/ComponentWrap'
import { StatsCounterComponent } from '@/types/cmsGenerated'

export default function Component({
    settings,
    componentContext,
}: StatsCounterComponent) {
    const { maxValue, speed, unit, description } = settings
    return (
        <ComponentWrap>
            <StatsCounter
                maxValue={maxValue}
                speed={speed}
                unit={unit}
                description={description}
            />
        </ComponentWrap>
    )
}
