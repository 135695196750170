'use client'
import {
    PropsWithChildren,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import { DirectionButton } from '../Button/DirectionButton'
import { Heading } from '../Heading'
import { Divider } from '@mui/material'
import useResponsive from '@/hooks/useResponsive'

const getItemWidth = (isDesktop: boolean) => (isDesktop ? 538 : 360)

interface Props {
    itemCount: number
    title: string
    subtitle: string
    logos?: JSX.Element | null
}
export default function ReferenceBox({
    children,
    itemCount,
    title,
    subtitle,
    logos,
}: PropsWithChildren<Props>) {
    const [left, setLeft] = useState(0)
    const rootEl = useRef<HTMLDivElement>(null)
    const { isDesktop } = useResponsive()
    const [itemWidth, setItemWidth] = useState(getItemWidth(isDesktop))

    const changeLeft = useCallback(
        (n: number) => () => {
            setLeft((currentLeft) => {
                const newLeft = currentLeft + n
                const max = isDesktop ? itemCount - 1 : itemCount
                if (newLeft < 0 || newLeft >= max) {
                    return currentLeft
                }
                return newLeft
            })
        },
        [itemCount, isDesktop]
    )

    useEffect(() => {
        const newWidth = getItemWidth(isDesktop)
        setItemWidth(newWidth)
        rootEl.current?.style.setProperty(
            '--max-w',
            `${newWidth * (isDesktop ? 2 : 1)}px`
        )
    }, [isDesktop])

    return (
        <div ref={rootEl} className="flex justify-center">
            <div className="max-w-[var(--max-w)]">
                <div className="flex justify-between items-center mb-6 md:mb-16 px-4">
                    <div>
                        <Heading text={title} tag="h5" className="uppercase" />
                        <span className="text-md">{subtitle}</span>
                    </div>
                    <div className="flex">
                        <DirectionButton
                            className="mr-4"
                            direction="left"
                            onClick={changeLeft(-1)}
                        />
                        <DirectionButton
                            direction="right"
                            onClick={changeLeft(1)}
                        />
                    </div>
                </div>
                <div
                    className="overflow-hidden mb-4 md:mb-16"
                    style={{
                        maxWidth: `${itemWidth * 2}px`,
                    }}
                >
                    <div
                        className="grid max-w-auto transition-all duration-300 items-start"
                        style={{
                            gridTemplateColumns: `repeat(${itemCount}, ${itemWidth}px)`,
                            transform: `translateX(${-(left * itemWidth)}px)`,
                        }}
                    >
                        {children}
                    </div>
                </div>
                <Divider />
                <div className="my-6 md:my-10 flex justify-around items-center flex-wrap gap-y-3">
                    {logos}
                </div>
            </div>
        </div>
    )
}
