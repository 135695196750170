import { TableRowProps } from '../types'

const TableRow = ({ rowItem }: TableRowProps) => {
    const tableRowStyle = 'border-b dark:border-neutral-500'
    const tableDataCellStyle = 'whitespace-nowrap px-6 py-4 font-medium'
    return (
        <tr className={tableRowStyle}>
            {rowItem &&
                Object.values(rowItem).map((value, idx) => (
                    <td key={idx} className={tableDataCellStyle}>
                        {value}
                    </td>
                ))}
        </tr>
    )
}

export default TableRow
