import Image from '@/components/Image'
import { generateImagePath } from '@/utils/functions/helpers'

type Props = {
    image1Path: string | undefined
    image1Alt: string | undefined
    image2Path: string | undefined
    image2Alt: string | undefined
    topText: string
    quoteBody: string
    quoteHeading: string
    authorImagePath: string | undefined
    authorName: string
    authorJobTitle: string
}

export const AboutUsTopBox = ({
    image1Path,
    image1Alt,
    image2Path,
    image2Alt,
    topText,
    quoteBody,
    quoteHeading,
    authorImagePath,
    authorName,
    authorJobTitle,
}: Props) => {
    return (
        <div className="bg-gray-light w-full mt-64 lg:mt-96">
            <div className="relative -top-64 -mb-64 lg:-top-96 lg:-mb-96 md:max-w-screen-xl md:mx-auto">
                <div className="flex gap-8 flex-col md:flex-row">
                    <div className="w-full md:w-3/5 flex flex-col">
                        <Image
                            alt={image1Alt || ''}
                            path={generateImagePath(image1Path)}
                        />
                        <div className="ml-20 mb-20">
                            <div className="relative -ml-20 mt-20">
                                <Image path="/quotes.svg" alt="quotes" />
                            </div>
                            <div className="-mt-20">
                                <div
                                    className={`text-blue-dark text-[34px] leading-[44px] font-light`}
                                >
                                    {quoteHeading}
                                </div>
                                <div className="mt-8 text-blue-for-body-text body3">
                                    {quoteBody}
                                </div>
                                <div className="flex items-center gap-4 mt-8">
                                    <Image
                                        className="w-16 h-16 rounded-full"
                                        path={generateImagePath(
                                            authorImagePath
                                        )}
                                        alt={authorName}
                                    />
                                    <div className="flex flex-col mr-4 text-left">
                                        <span className="text-md text-blue-dark font-bold">
                                            {authorName}
                                        </span>
                                        <span className="text-blue-for-body-text">
                                            {authorJobTitle}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-2/5 md:px-4 lg:px-20 flex flex-col items-start">
                        <div className="my-4 ml-16 md:ml-0 lg:my-16">
                            <Image path="/arrowDown.svg" alt="arrow" />
                        </div>
                        <div className="mb-8 lg:mb-16 px-4 md:px-0">
                            {topText}
                        </div>
                        <Image
                            alt={image2Alt || ''}
                            path={generateImagePath(image2Path)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
