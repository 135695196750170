import { MapLayoutComponent } from '@/types/cmsGenerated'
import { ComponentWrap } from '@/components/ComponentWrap'
import CompanyInfoWrapper from '@/components/CompanyInfo/Wrapper'

export default function Component({
    settings,
    componentContext,
}: MapLayoutComponent) {
    const { title, companyInfo } = settings

    return (
        <ComponentWrap>
            <CompanyInfoWrapper
                title={title}
                companyInfo={companyInfo}
                componentContext={componentContext}
            />
        </ComponentWrap>
    )
}
