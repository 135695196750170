import clsx from 'clsx'
import { Button } from '@/components/Button'
import { Dropdown } from '@/components/Dropdown'
import { Bars3Icon } from '@heroicons/react/20/solid'
import useResponsive from '@/hooks/useResponsive'
import { useMemo } from 'react'
import NavbarSearch from '@/eshop/components/Navbar/Search'

type Classnames = {
    desktopButton?: (baseClassNames: string) => string
    desktopButtonList?: (baseClassNames: string) => string
}

type Link = {
    link: string
    label: string
}

export type LinkArrayProps = {
    links: Link[]
    responsive?: boolean
    column?: boolean
    activeLinkIndex?: number
    classNames?: Classnames
}

const baseDesktopButtonClassNames: string =
    'no-underline text-blue-dark font-normal opacity-60 whitespace-nowrap'

const LinkArray = ({
    responsive,
    column,
    links,
    classNames,
    activeLinkIndex,
}: LinkArrayProps) => {
    const { isDesktop } = useResponsive()

    const baseDesktopButtonListClassNames = clsx('flex', {
        'flex-col': column,
    })

    const preparedClassNames = useMemo(
        () => ({
            desktopButton: classNames?.desktopButton?.(
                baseDesktopButtonClassNames
            ),
            desktopButtonList: classNames?.desktopButtonList?.(
                baseDesktopButtonListClassNames
            ),
        }),
        [baseDesktopButtonListClassNames, classNames]
    )

    const desktopButtonClassNames =
        preparedClassNames?.desktopButton || baseDesktopButtonClassNames

    const desktopActiveButtonClassNames = (index: number) =>
        activeLinkIndex === index
            ? 'border-b-[1px] border-t-0 border-l-0 border-r-0 border-blue-dark rounded-none !opacity-100 hover:border-current'
            : ''

    return (
        <>
            {isDesktop || !responsive ? (
                <div
                    className={
                        preparedClassNames?.desktopButtonList ||
                        baseDesktopButtonListClassNames
                    }
                >
                    <NavbarSearch />
                    {links.map((link, index) => (
                        <Button
                            key={index}
                            rootClasses={`${desktopButtonClassNames} ${desktopActiveButtonClassNames(
                                index
                            )}`}
                            url={link.link}
                            color="link"
                        >
                            {link.label}
                        </Button>
                    ))}
                </div>
            ) : (
                <Dropdown
                    label={<Bars3Icon className="min-w-[20px]" />}
                    labelClassName="p-2"
                    items={links.map((link) => link)}
                    activeItemIndex={activeLinkIndex}
                />
            )}
        </>
    )
}

export default LinkArray
