'use client'
import Link from 'next/link'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useResizeWindow } from '@/hooks/useResizeWindow'

type DropdownLinksProps = {
    text: string
    url: string
    dropdownLinks: any[]
}

export const DropdownLink = ({
    text,
    url,
    dropdownLinks,
}: DropdownLinksProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    // Check for the user's device
    const handleResizeWindow = useCallback(() => {
        window.innerWidth > 768 ? setIsMobile(false) : setIsMobile(true)
    }, [])

    useResizeWindow(handleResizeWindow)

    return (
        <>
            {/* Render content based on the user's device */}
            {!isMobile ? (
                <DesktopDropdown
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    text={text}
                    url={url}
                    dropdownLinks={dropdownLinks}
                />
            ) : (
                <MobileDropdown
                    text={text}
                    url={url}
                    dropdownLinks={dropdownLinks}
                />
            )}
        </>
    )
}

// Desktop menu dropdown
const DesktopDropdown = ({
    isOpen,
    setIsOpen,
    text,
    url,
    dropdownLinks,
}: {
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
    text: string
    url: string
    dropdownLinks: any[]
}) => {
    const purpleUnderlineAnimation =
        'bg-left-bottom bg-gradient-to-r bg-[length:0%_2px] bg-no-repeat hover:bg-[length:100%_2px] transition-all duration-300 ease-out'
    const whiteUnderlineAnimation =
        'bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat hover:bg-[length:100%_2px] transition-all duration-300 ease-out'
    const menuLinkDesktopStyles = 'py-2.5 px-4 text-black'
    const submenuLinkDesktopStyles =
        'text-[#ded9d9] hover:text-white font-semibold py-1 px-4'

    return (
        <div
            // trigger the dropdown
            onMouseOver={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            className="w-fit uppercase text-sm font-medium"
        >
            <Link className={menuLinkDesktopStyles} href={url || '#'}>
                <span className={purpleUnderlineAnimation}>{text}</span>
            </Link>
            {isOpen && !!dropdownLinks && (
                <div className="bg-black/80 w-fit flex flex-col absolute mt-2.5 py-2.5">
                    {dropdownLinks?.map((link, index) => (
                        <Link
                            key={index}
                            href={link.value.url || '#'}
                            className={submenuLinkDesktopStyles}
                        >
                            <span className={whiteUnderlineAnimation}>
                                {link.value.title}
                            </span>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    )
}

// Mobile menu dropdown
const MobileDropdown = ({
    text,
    url,
    dropdownLinks,
}: {
    text: string
    url: string
    dropdownLinks: any[]
}) => {
    return (
        <div className="flex flex-col uppercase font-semibold">
            <Link href={url || '#'} className="px-2.5 py-3">
                {text}
            </Link>
            {!!dropdownLinks &&
                dropdownLinks.map((link, index) => (
                    <Link
                        href={link.value.url || '#'}
                        key={index}
                        className="py-2 px-3.5 text-sm underline"
                    >{`- ${link.value.title}`}</Link>
                ))}
        </div>
    )
}
