import { TextProps } from '@/components/Text/types'
import clsx from 'clsx'

export enum TextColorkeys {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    BLUE_DARK = 'blue-dark',
    BLUE_MIDDLE = 'blue-middle',
    BLUE_MEDIUM = 'blue-medium',
    BLUE_FOR_BODY_TEXT = 'blue-for-body-text',
    WHITE = 'white',
    GRAY_LIGHT = 'gray-light',
    GRAY_LIGHT_2 = 'gray-light2',
    GRAY_MIDDLE = 'gray-middle',
    ORANGE = 'orange',
}

export const colorMap: Map<TextColorkeys, string> = new Map([
    [TextColorkeys.PRIMARY, 'text-primary'],
    [TextColorkeys.SECONDARY, 'text-secondary'],
    [TextColorkeys.BLUE_DARK, 'text-blue-dark'],
    [TextColorkeys.BLUE_MIDDLE, 'text-blue-middle'],
    [TextColorkeys.BLUE_MEDIUM, 'text-blue-medium'],
    [TextColorkeys.BLUE_FOR_BODY_TEXT, 'text-blue-for-body-text'],
    [TextColorkeys.WHITE, 'text-white'],
    [TextColorkeys.GRAY_LIGHT, 'text-gray-light'],
    [TextColorkeys.GRAY_LIGHT_2, 'text-gray-light2'],
    [TextColorkeys.GRAY_MIDDLE, 'text-gray-middle'],
    [TextColorkeys.ORANGE, 'text-orange'],
])

export enum TextVariantKeys {
    SMALL = 'small',
    BASE = 'base',
    MEDIUM = 'medium',
    BIG = 'big',
}

const variantMap: Map<TextVariantKeys, string> = new Map([
    [TextVariantKeys.SMALL, 'text-sm'],
    [TextVariantKeys.BASE, 'text-base leading-none'],
    [TextVariantKeys.MEDIUM, 'text-md'],
    [TextVariantKeys.BIG, 'text-lg2'],
])

export const Text = ({ text, color, variant, className = '' }: TextProps) => {
    const colorStyle = color ? colorMap.get(color) : ''
    const variantStyle = variant ? variantMap.get(variant) : ''

    return (
        <span
            className={clsx(colorStyle, variantStyle, className)}
            dangerouslySetInnerHTML={{ __html: text }}
        />
    )
}
