import { Hero } from '@/components/Hero'
import { ComponentWrap } from '@/components/ComponentWrap'
import { HeroComponent } from '@/types/cmsGenerated'
import React from 'react'
import { generateImagePath } from '@/utils/functions/helpers'
import { DirectionButton } from '@/components/Button/DirectionButton'
import { Button } from '@/components/Button'
import Image from '@/components/Image'
import { Heading } from '@/components/Heading'

export default function Component({ settings }: HeroComponent) {
    const {
        backgroundImage,
        title,
        subtitle,
        linkText,
        linkUrl,
        tileImage,
        tileTitle,
        tileSubtitle,
        tileLinkUrl,
        fontSize,
    } = settings

    let fontSizeStyle = {}

    if (!!fontSize && typeof fontSize === 'number') {
        fontSizeStyle = {
            fontSize: `${Number(fontSize)}px`,
        }
    }

    return (
        <ComponentWrap>
            <Hero
                contentClasses="min-h-screen items-stretch"
                bgImageUrl={generateImagePath(backgroundImage?.path)}
                className="bg-contain sm:bg-cover"
            >
                <div className="flex flex-col items-center grow">
                    <div className="max-w-[94rem] w-full flex-grow flex items-center p-8">
                        <div className="lg:w-1/2">
                            <h1
                                style={fontSizeStyle}
                                className={'text-2xl lg:text-5xl'}
                            >
                                {title}
                            </h1>
                            <p className="pb-6 pt-[42px] text-lg2">
                                {subtitle}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col lg:block lg:relative lg:w-full">
                        <div className="hidden sm:flex max-w-[94rem] mx-auto p-8 items-center justify-between backdrop-blur bg-base-100/80 lg:bg-transparent lg:backdrop-blur-none">
                            <div className="flex gap-4 items-center">
                                <DirectionButton
                                    url={linkUrl}
                                    direction="down"
                                />
                                <Button
                                    rootClasses="text-blue-dark no-underline hover:no-underline"
                                    color="link"
                                    url={linkUrl}
                                >
                                    <span className=" border-0 border-b border-blue-dark pb-[3px] text-base">
                                        {linkText}
                                    </span>
                                </Button>
                            </div>
                        </div>
                        <div className="w-max-fit p-4 right-0 bottom-0 -mb-4 flex bg-base-100/80 backdrop-blur lg:absolute">
                            <div className="flex items-center">
                                {tileImage && (
                                    <Image
                                        className="hidden sm:inline w-20 h-20 sm:w-28 sm:h-28 rounded-full"
                                        path={generateImagePath(tileImage.path)}
                                        alt={tileImage.title}
                                    />
                                )}
                                <div className="flex flex-col justify-center mx-4 sm:mx-8">
                                    <Heading tag="h4" text={tileTitle} />
                                    <div className="body2 text-blue-for-body-text">
                                        {tileSubtitle}
                                    </div>
                                </div>
                                <DirectionButton
                                    className="flex sm:flex mx-0 sm:mx-8"
                                    direction="right"
                                    url={tileLinkUrl}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Hero>
        </ComponentWrap>
    )
}
