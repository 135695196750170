import TableBody from './elements/TableBody'
import TableHead from './elements/TableHeading'
import { TableDataProps } from './types'

const Table = ({ tableData }: TableDataProps) => {
    const tableStyle = 'min-w-full text-left text-sm font-light'
    const wrapperStyle = 'shadow-box mx-auto p-10 w-full md:w-[83.3%]'
    return (
        <div className={wrapperStyle}>
            <table className={tableStyle}>
                <TableHead tableData={tableData} />
                <TableBody tableData={tableData} />
            </table>
        </div>
    )
}

export default Table
