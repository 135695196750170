type Props = {
    name: string
    position: string
    email?: string
    phone?: string
}

export const PersonalContactInfo = ({
    name,
    position,
    email,
    phone,
}: Props) => (
    <div className="flex flex-col">
        <div className="body1">{name}</div>
        <div className="body3">{position}</div>
        <div className="flex justify-between flex-wrap">
            {email && (
                <div className="mt-4 mr-4">
                    E-mail:{' '}
                    <a
                        className="pl-8 text-primary underline"
                        href={`mailto:${email}`}
                    >
                        {email}
                    </a>
                </div>
            )}
            {phone && (
                <div className="mt-4">
                    Phone:{' '}
                    <a
                        className="pl-8 text-primary underline"
                        href={`tel:${phone}`}
                    >
                        {phone}
                    </a>
                </div>
            )}
        </div>
    </div>
)
