import { renderLayout } from '../.'
import { ComponentWrap } from '@/components/ComponentWrap'
import { SectionComponent } from '@/types/cmsGenerated'

export default function Component({
    children,
    settings,
    componentContext,
}: SectionComponent) {
    const { id } = settings

    return (
        <ComponentWrap>
            <div id={id}>
                <div className="h-[64px]" />
                {renderLayout(children, componentContext)}
            </div>
        </ComponentWrap>
    )
}
