import { ComponentWrap } from '@/components/ComponentWrap'
import { AssetComponent } from '@/types/cmsGenerated'
import { Heading } from '@/components/Heading'
import Image from '@/components/Image'
import clsx from 'clsx'
import { generateImagePath } from '@/utils/functions/helpers'

const DEFAULT_ROUNDED_SIZE = '160px'

const getSizeFromStr = (width?: string, height?: string) => {
    const size = (width || height || DEFAULT_ROUNDED_SIZE).trim()
    if (
        ['px', 'rem', 'em'].some(
            (unit) => size.substring(size.length - unit.length) === unit
        )
    ) {
        return size
    }
    const num = parseInt(size, 10)
    return Number.isNaN(num) ? DEFAULT_ROUNDED_SIZE : `${num}px`
}

export default function Component({ settings }: AssetComponent) {
    const { asset, width, height, alt, rounded } = settings
    const size = rounded ? getSizeFromStr(width, height) : undefined

    if (asset?.image) {
        return (
            <ComponentWrap>
                <Image
                    path={generateImagePath(asset?.path)}
                    width={rounded ? size : width}
                    height={rounded ? size : height}
                    alt={alt}
                    className={clsx(
                        rounded && 'rounded-full object-cover aspect-square'
                    )}
                />
            </ComponentWrap>
        )
    }

    return (
        <div>
            <Heading
                text="This type of asset is not supported yet"
                tag="h2"
                align="center"
            />
        </div>
    )
}
