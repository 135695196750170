import { Info_boxComponent } from '@/types/cmsGenerated'
import { renderLayout } from '@/cms_components'

export default function Component({
    settings,
    componentContext,
}: Info_boxComponent) {
    const { layout } = settings
    return (
        <div className="flex flex-col gap-8 items-center max-w-[26.375rem] my-8 mx-auto">
            {renderLayout(layout, componentContext)}
        </div>
    )
}
