'use client'
import { ComponentWrap } from '@/components/ComponentWrap'
import { LinkArrayComponent } from '@/types/cmsGenerated'
import LinkArray from '@/components/LinkArray'
import { mapFromValue } from '@/utils/functions/mapFromValue'

export default function Component({ settings }: LinkArrayComponent) {
    const { links, responsive, column } = settings

    return (
        <ComponentWrap>
            <LinkArray
                links={mapFromValue(links)}
                responsive={responsive}
                column={column}
            />
        </ComponentWrap>
    )
}
