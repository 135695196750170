import { PersonalContactInfoComponent } from '@/types/cmsGenerated'
import { PersonalContactInfo } from '@/components/PersonalContactInfo'

export default function Component({ settings }: PersonalContactInfoComponent) {
    const { name, position, email, phone } = settings

    return (
        <PersonalContactInfo
            name={name}
            position={position}
            email={email}
            phone={phone}
        />
    )
}
