import { ComponentWrap } from '@/components/ComponentWrap'
import { LinkArrayComponent } from '@/types/cmsGenerated'
import { getContactInfoById, getNavigationCardByName } from '@/api/api'
import { NavigationCard } from '@/components/NavigationCard'
import { NavigationCardVariant } from '@/components/NavigationCard/types'
import { renderLayout } from '@/cms_components'
import { ReactElement } from 'react'

const variantMap = new Map([
    ['Map Top', NavigationCardVariant.MAP_TOP],
    ['Map Left', NavigationCardVariant.MAP_LEFT],
    ['Map Right', NavigationCardVariant.MAP_RIGHT],
])

const latLongZoomOut = 0.02

export default async function Component({
    settings,
    componentContext,
}: LinkArrayComponent) {
    const {
        navigationCardId,
        mapLatitude,
        mapLongitude,
        contactInfoId,
        contactInfoLabel,
        companyInfoLabel,
        addressLabel,
        phoneLabel,
        shippingHoursLabel,
        companyVatIdLabel,
        companyTaxIdLabel,
        emailLabel,
        contactFormButtonLabel,
        headingLabel,
    } = settings as any
    const variantName = navigationCardId?.display
    const variantKey = [...variantMap.keys()].find((k) =>
        variantName.includes(k)
    )

    const contactInfoData = await getContactInfoById(
        contactInfoId._id,
        componentContext.lang
    )
    const navigationCardData = await getNavigationCardByName(
        variantName,
        componentContext.lang
    )
    const {
        address,
        shipping_hours,
        phone,
        e_mail,
        company_vat_id,
        company_tax_id,
    } = contactInfoData.data.layout[0].settings

    return (
        <ComponentWrap>
            <NavigationCard
                address={address}
                shippingHours={shipping_hours}
                phoneText={phone}
                email={e_mail}
                companyVatId={company_vat_id}
                companyTaxId={company_tax_id}
                phoneForLink={phone}
                mapCoordinates={{
                    bbox: {
                        minLongitude: mapLongitude - latLongZoomOut,
                        minLatitude: mapLatitude + latLongZoomOut,
                        maxLongitude: mapLongitude + latLongZoomOut,
                        maxLatitude: mapLatitude - latLongZoomOut,
                    },
                    marker: {
                        latitude: mapLatitude,
                        longitude: mapLongitude,
                    },
                }}
                variant={!!variantKey ? variantMap.get(variantKey) : undefined}
                additionalContainerContent={
                    renderLayout(
                        navigationCardData?.data?.layout
                    ) as ReactElement
                }
                additionalOuterContent={
                    renderLayout(
                        navigationCardData?.data?.surroundingLayout
                    ) as ReactElement
                }
                contactInfoLabel={contactInfoLabel || undefined}
                companyInfoLabel={companyInfoLabel || undefined}
                addressLabel={addressLabel || undefined}
                phoneLabel={phoneLabel || undefined}
                shippingHoursLabel={shippingHoursLabel || undefined}
                companyVatIdLabel={companyVatIdLabel || undefined}
                companyTaxIdLabel={companyTaxIdLabel || undefined}
                emailLabel={emailLabel || undefined}
                contactFormButtonLabel={contactFormButtonLabel || undefined}
                headingLabel={headingLabel}
            />
        </ComponentWrap>
    )
}
