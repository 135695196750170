import { ValuesTableComponent } from '@/types/cmsGenerated'
import { SimpleTable, TableData } from '@/components/SimpleTable'
import { useMemo } from 'react'

export default function Component({ settings }: ValuesTableComponent) {
    const { title, table } = settings

    const tableData: TableData = useMemo(() => {
        return table.map((row) => [
            {
                value: row.value.title,
                tag: 'h4',
                classes: 'text-blue-dark pl-0 py-8 align-top',
            },
            {
                value: row.value.text,
                classes:
                    'pr-0 py-8 text-blue-for-body-text body2 max-w-[208px]',
            },
        ])
    }, [table])
    return (
        <div className="mx-4 w-full">
            <SimpleTable
                title={title}
                rootClasses="px-4 max-w-[684px] mb-16"
                tableData={tableData}
            />
        </div>
    )
}
