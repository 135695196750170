import { useEffect, useMemo, useState } from 'react'

export default function useResponsive() {
    // TODO: use library for that :)
    const [isDesktop, setIsDesktop] = useState(true)

    useEffect(() => {
        setIsDesktop(window.innerWidth > 1080)

        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                // const newWidth = entry.contentRect.width
                setIsDesktop(window.innerWidth > 1080)
            }
        })

        resizeObserver.observe(document.body)

        return () => {
            resizeObserver.unobserve(document.body)
            resizeObserver.disconnect()
        }
    }, [])

    // TODO: add resize listener

    return useMemo(() => ({ isDesktop }), [isDesktop])
}
