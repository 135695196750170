export type Group = {
    groupName: string
    values: (null | JSX.Element)[]
}

type Props = {
    title: string
    groups: Group[]
}

export const GroupedTable = ({ title, groups }: Props) => (
    <div className="md:mx-auto md:max-w-[1076px] w-full px-4 my-8">
        <h5 className="uppercase mb-8">{title}</h5>
        <table className="table">
            <tbody className="border-y border-gray-light2">
                {groups.map((group, rowIndex) => (
                    <tr
                        key={rowIndex}
                        className="border-b border-gray-light2 flex flex-col sm:table-row"
                    >
                        <td className="pl-0 align-top">
                            <h5 className="pt-4">{group.groupName}</h5>
                        </td>
                        <td className="pr-0 grow">
                            <table className="table">
                                <tbody>
                                    {group.values?.map((value, valueIndex) => (
                                        <tr
                                            key={valueIndex}
                                            className="border-b border-gray-light2 last:border-0"
                                        >
                                            <td className="px-0">{value}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
)
