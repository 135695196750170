'use client'
import { NavigationCardProps } from '@/components/NavigationCard/types'
import { useEffect, useMemo, useState } from 'react'

interface NavigationCard {
    mapBboxCoordinates: string
    mapMarkerCoordinates: string
}

export const useNavigationCard = (
    mapCoordinates: NavigationCardProps['mapCoordinates']
) => {
    return useMemo(
        (): NavigationCard => ({
            mapBboxCoordinates: `${encodeURIComponent(
                mapCoordinates.bbox.minLongitude
            )},${encodeURIComponent(
                mapCoordinates.bbox.minLatitude
            )},${encodeURIComponent(
                mapCoordinates.bbox.maxLongitude
            )},${encodeURIComponent(mapCoordinates.bbox.maxLatitude)}`,
            mapMarkerCoordinates: `${encodeURIComponent(
                mapCoordinates.marker.latitude
            )},${encodeURIComponent(mapCoordinates.marker.longitude)}`,
        }),
        [mapCoordinates.bbox, mapCoordinates.marker]
    )
}

interface UseNavigationCardIframeProps extends NavigationCard {
    width?: string
    height?: string
}
export const useNavigationCardIframe = ({
    mapBboxCoordinates,
    mapMarkerCoordinates,
    width,
    height,
}: UseNavigationCardIframeProps) => {
    const [showMap, setShowMap] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setShowMap(true)
        }, 1_000)
    }, [])

    const iframe = useMemo(() => {
        return (
            <iframe
                width={width}
                height={height}
                src={`https://www.openstreetmap.org/export/embed.html?bbox=${mapBboxCoordinates}&layer=mapnik&marker=${mapMarkerCoordinates}`}
            />
        )
    }, [mapBboxCoordinates, mapMarkerCoordinates, width, height])

    return showMap ? iframe : null
}
