'use client'
import { MapLayoutComponent } from '@/types/cmsGenerated'
import { Heading } from '@/components/Heading'
import { useCallback, useMemo, useState } from 'react'
import { CompanyInfo } from '@/components/CompanyInfo'
import { generateImagePath } from '@/utils/functions/helpers'
import { renderLayout } from '@/cms_components'

interface Props {
    companyInfo: MapLayoutComponent['settings']['companyInfo']
    title: MapLayoutComponent['settings']['title']
    componentContext: MapLayoutComponent['componentContext']
}
export default function CompanyInfoWrapper({
    title,
    companyInfo,
    componentContext,
}: Props) {
    const [index, setIndex] = useState(0)

    const actualCompanyInfo = useMemo(
        () => companyInfo[index],
        [companyInfo, index]
    )

    const actualCompanyChildren = useMemo(
        () => renderLayout(actualCompanyInfo.value.layout, componentContext),
        [actualCompanyInfo, componentContext]
    )

    const actualImagePath = useMemo(
        () => generateImagePath(actualCompanyInfo.value.map?.path),
        [actualCompanyInfo]
    )

    const prevClick = useCallback(() => {
        if (index === 0) {
            setIndex(companyInfo.length - 1)
        } else {
            setIndex(index - 1)
        }
    }, [companyInfo, index])

    const nextClick = useCallback(() => {
        if (index === companyInfo.length - 1) {
            setIndex(0)
        } else {
            setIndex(index + 1)
        }
    }, [companyInfo, index])

    return (
        <div>
            <div className="bg-gray-light p-16">
                <div className="flex justify-center mb-20">
                    <Heading tag="h4" text={title} />
                </div>
                <CompanyInfo
                    title={actualCompanyInfo.value.title}
                    text={actualCompanyInfo.value.text}
                    sectors={
                        actualCompanyInfo.value.sectors?.map(
                            (sector: any) => sector.value
                        ) || []
                    }
                    imageAlt={actualCompanyInfo.value.map?.title || ''}
                    imagePath={actualImagePath}
                    prevClick={prevClick}
                    nextClick={nextClick}
                >
                    {actualCompanyChildren}
                </CompanyInfo>
            </div>
        </div>
    )
}
