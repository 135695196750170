'use client'
import i18next from 'i18next'
import { PropsWithChildren, useCallback, useRef, useState } from 'react'
import { createContext } from 'react'
import { initReactI18next } from 'react-i18next'
import 'react-toastify/dist/ReactToastify.css'
import { Language } from '@/utils/language'

const createInstance = (t: Record<string, string>) =>
    i18next.use(initReactI18next).init({
        lng: 'en', // if you're using a language detector, do not define the lng option
        // TODO: debug only on test
        debug: false,
        resources: {
            en: {
                translation: t,
            },
        },
    })

export const CmsContext = createContext<{
    lang: Language
    t: any
}>({
    lang: 'cs',
    t: undefined,
})

export function CmsContextProvider({
    children,
    defaultLang,
    translation,
}: PropsWithChildren<{
    defaultLang: Language
    translation: Record<string, string>
}>) {
    const [i18instance] = useState(() => createInstance(translation))

    return (
        <CmsContext.Provider
            value={{
                lang: defaultLang,
                t: i18instance,
            }}
        >
            {children}
        </CmsContext.Provider>
    )
}
