'use client'
import Image from '@/components/Image'
import { Heading } from '@/components/Heading'
import { useMemo } from 'react'
import { DirectionButton } from '@/components/Button/DirectionButton'
import { Stepper } from '@/components/Stepper'

export type TimelineDataItem = {
    year: string
    text: string
    imagePath: string
    imageTitle: string
}

type Props = {
    timelineData: TimelineDataItem[]
    activeIndex: number
    nextClick: () => void
    prevClick: () => void
}

export const Timeline = ({
    timelineData,
    activeIndex,
    prevClick,
    nextClick,
}: Props) => {
    const activeItem = useMemo(
        () => timelineData[activeIndex],
        [activeIndex, timelineData]
    )

    const steps = useMemo(
        () =>
            timelineData.map((item, index) => ({
                key: item.year,
                name: item.year,
                active: activeIndex === index,
            })),
        [timelineData, activeIndex]
    )
    return (
        <div className="w-full md:max-w-screen-xl md:mx-auto">
            <div className="flex flex-col w-full items-center">
                <Stepper steps={steps} />
                <div className="flex flex-col lg:flex-row gap-8 my-20">
                    <div className="mx-auto lg:ml-40">
                        <div className="w-80 h-80 rounded-full aspect-square overflow-hidden">
                            <Image
                                className="w-80 h-80 object-cover"
                                path={activeItem.imagePath}
                                alt={activeItem.imageTitle}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-8 mx-8 items-start">
                        <Heading tag="h3" text={activeItem.year} />
                        <div className="body2 lg:mr-20">{activeItem.text}</div>
                        <div className="flex justify-start gap-4">
                            <DirectionButton
                                direction="left"
                                onClick={prevClick}
                            />
                            <DirectionButton
                                direction="right"
                                onClick={nextClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
