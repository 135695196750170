'use client'
import { TimelineLayoutComponent } from '@/types/cmsGenerated'
import { Timeline } from '@/components/Timeline'
import { getTimelineDataFromCms } from '@/utils/functions/helpers'
import { useCallback, useState } from 'react'
import { Heading } from '@/components/Heading'
import { useMemo } from 'react'

export default function Component({ settings }: TimelineLayoutComponent) {
    const { title, perex, timelineData } = settings
    const [index, setIndex] = useState(0)
    const timelineDataTrans = useMemo(
        () => getTimelineDataFromCms(timelineData),
        [timelineData]
    )

    const prevClick = useCallback(() => {
        if (index === 0) {
            setIndex(timelineData.length - 1)
        } else {
            setIndex(index - 1)
        }
    }, [timelineData, index])

    const nextClick = useCallback(() => {
        if (index === timelineData.length - 1) {
            setIndex(0)
        } else {
            setIndex(index + 1)
        }
    }, [timelineData, index])

    return (
        <div className="flex flex-col items-center my-8">
            {title && (
                <div className="mb-8">
                    <Heading text={title} tag="h5" />
                </div>
            )}
            {perex && <div className="mb-20 body2 mx-8">{perex}</div>}
            <Timeline
                timelineData={timelineDataTrans}
                activeIndex={index}
                prevClick={prevClick}
                nextClick={nextClick}
            />
        </div>
    )
}
