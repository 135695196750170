import { About_Us_Top_BoxComponent } from '@/types/cmsGenerated'
import { getBlockquoteById } from '@/api/api'
import { AboutUsTopBox } from '@/components/AboutUsTopBox'

export default async function Component({
    settings,
    componentContext,
}: About_Us_Top_BoxComponent) {
    const { image_1, image_2, top_text, blockquote } = settings

    if (!blockquote) {
        return null
    }

    const res = await getBlockquoteById(blockquote._id, componentContext.lang)

    if (!res.data) {
        return null
    }

    return (
        <AboutUsTopBox
            image1Path={image_1?.path}
            image1Alt={image_1?.title}
            image2Path={image_2?.path}
            image2Alt={image_2?.title}
            topText={top_text}
            quoteBody={res.data.blockquote[0].settings.quote_body}
            quoteHeading={res.data.blockquote[0].settings.blockquote_heading}
            authorImagePath={res.data.blockquote[0].settings.author_image?.path}
            authorName={res.data.blockquote[0].settings.author}
            authorJobTitle={res.data.blockquote[0].settings.author_job_title}
        />
    )
}
