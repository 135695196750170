import { Tab } from '@/components/Tabs'
import { renderLayout } from '@/cms_components'
import { LayoutProp } from '@/types/cmsTypesGeneration/cmsStatic'

const ASSETS_URL = process.env.NEXT_PUBLIC_CMS_ASSET_STORAGE_URL

export const generateImagePath = (path?: string): string =>
    path ? ASSETS_URL + path : ''

export const getTabsDefinitionFromCms = (
    tabs: {
        value: { Label: string; Layout: LayoutProp }
        field?: { type: string }
    }[]
): Tab[] =>
    tabs.map((tab) => ({
        key: tab.value.Label,
        label: tab.value.Label,
        content: renderLayout(tab.value.Layout),
    }))

export const getTimelineDataFromCms = (
    timelineData: {
        value: { year: string; text: string; image: any }
        field?: { type: string }
    }[]
) =>
    timelineData.map((timelineItem) => ({
        year: timelineItem.value.year,
        text: timelineItem.value.text,
        imagePath: generateImagePath(timelineItem.value.image?.path),
        imageTitle: timelineItem.value.image?.title,
    }))
