'use client'
import { ReactNode, useState } from 'react'
import EshopModal from '@/eshop/components/Modal'

export const Modal = ({
    button,
    children,
}: {
    button: ReactNode
    children: ReactNode
}) => {
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            <div className="max-w-fit" onClick={() => setShowModal(true)}>
                {button}
            </div>
            <EshopModal show={showModal} onHide={() => setShowModal(false)}>
                {children}
            </EshopModal>
        </>
    )
}
