'use client'
import { NavigationCardProps } from '@/components/NavigationCard/types'
import { cardColumnBaseClasses } from '@/components/NavigationCard/constants'
import {
    useNavigationCard,
    useNavigationCardIframe,
} from '@/components/NavigationCard/useNavigationCard'
import { ContactInfo } from '@/components/ContactInfo'
import { Divider, DividerColorkeys } from '@/components/Divider'
import { useEffect, useState } from 'react'

/**
 * Renders the navigation card.
 * @param address an address
 * @param mapCoordinates an object providing coordinates for the map (more precisely for the bounding box and marker) is expected
 * @param shippingHours shipping hours
 * @param phoneForLink a phone number dialed by Skype
 * @param phoneText text for a phone number that will exist as a clickable link
 * @param email an email
 * @param className an className for style sheets
 */
export const NavigationCardMapLeft = ({
    address,
    mapCoordinates,
    shippingHours,
    phoneForLink,
    phoneText,
    email,
    additionalContainerContent,
    rootClasses = '',
    contactInfoLabel,
    addressLabel,
    phoneLabel,
    shippingHoursLabel,
    emailLabel,
    headingLabel,
}: NavigationCardProps): JSX.Element => {
    const { mapBboxCoordinates, mapMarkerCoordinates } =
        useNavigationCard(mapCoordinates)

    const iframe = useNavigationCardIframe({
        mapBboxCoordinates,
        mapMarkerCoordinates,
        width: '100%',
    })

    return (
        <div
            className={`flex flex-col-reverse w-full bg-gray-light gap-4 pr-0 md:pr-5 md:gap-14 md:flex-row lg:gap-7 ${rootClasses}`}
        >
            <div
                className={`${cardColumnBaseClasses} mt-6 ml-0 h-64 sm:ml-0 sm:mt-6 md:h-auto md:mt-0 !md:w-2/4`}
            >
                {iframe}
            </div>
            <div className={'grow pt-32 mb-20'}>
                <div className={'inline-block'}>
                    <div className={'pl-4 md:pl-0'}>
                        {additionalContainerContent}
                    </div>
                    <Divider
                        width={'thin'}
                        type={'solid'}
                        className={'ml-4 mb-20 mt-16'}
                        color={DividerColorkeys.BLUE_LIGHT}
                    />
                    <ContactInfo
                        address={address}
                        phoneForLink={phoneForLink}
                        phoneText={phoneText}
                        shippingHours={shippingHours}
                        email={email}
                        headingLabel={headingLabel}
                        rootClasses={'pl-2.5 sm:pl-4 '}
                        variant={'navigationCardMapLeft'}
                        contactInfoLabel={contactInfoLabel}
                        addressLabel={addressLabel}
                        phoneLabel={phoneLabel}
                        shippingHoursLabel={shippingHoursLabel}
                        emailLabel={emailLabel}
                    />
                </div>
            </div>
        </div>
    )
}
