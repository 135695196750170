import { SelectOption } from '@/components/FormComponents/types'
import { phoneValidation } from '@/utils/schema'
import { z } from 'zod'

export const schema = z
    .object({
        fname: z.string().min(3),
        lname: z.string().min(3),
        message: z.string(),
        email: z.string().email(),
        tel: phoneValidation.optional(),
        company: z.string().optional(),
        solution: z.string().length(1),
        personal_data: z.boolean(),
    })
    .refine((obj) => !!obj.personal_data, {
        message: 'You must agree with personal data processing.',
        path: ['personal_data'],
    })
export type ContactFormFields = z.infer<typeof schema>

export const solutionOpts: SelectOption[] = [
    'Web Solutions',
    'Enterprise Solutions',
    'Testing Solutions',
    'Mobile Solutions',
].map((label, i) => ({
    value: i.toString(),
    label,
}))
