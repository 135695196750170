import { Button } from '@/components/Button/index'
import clsx from 'clsx'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import React from 'react'

export type Direction = 'up' | 'down' | 'left' | 'right'

type Props = {
    direction: Direction
    url?: string
    active?: boolean
    onClick?: (event: Event) => void
    className?: string
    disabled?: boolean
}

export const DirectionButton = ({
    direction,
    url,
    active,
    onClick,
    className,
    disabled,
}: Props) => {
    return (
        <Button
            type="circle"
            rootClasses={clsx(
                'border-neutral hover:bg-neutral hover:border-neutral hover:text-black',
                className
            )}
            url={url}
            active={active}
            onClick={onClick}
            disabled={disabled}
            outline
        >
            <ChevronDownIcon
                className={clsx('w-6 h-6', {
                    '-rotate-90': direction === 'right',
                    'rotate-180': direction === 'up',
                    'rotate-90': direction === 'left',
                })}
            />
        </Button>
    )
}
