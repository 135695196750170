import clsx from 'clsx'

export type Props = {
    items: {
        label: string
        body: JSX.Element | null
    }[]
    singleOpen: boolean
    id: string
    rootClasses?: string
}

export default function Component({
    items,
    singleOpen,
    id,
    rootClasses,
}: Props) {
    if (!items || !Array.isArray(items)) return null

    return (
        <div className={rootClasses}>
            {items.map(({ label, body }, index) => (
                <div
                    key={index}
                    className={clsx(
                        'collapse collapse-plus border-b rounded-none border-neutral'
                    )}
                >
                    <input type={singleOpen ? 'radio' : 'checkbox'} name={id} />
                    <div
                        className={clsx(
                            'collapse-title text-xl pl-0',
                            'after:!top-0 after:!flex after:!items-center after:!h-full'
                        )}
                    >
                        <div className="font-bold text-md leading-0">
                            {label}
                        </div>
                    </div>
                    <div className="collapse-content text-base pl-0">
                        {body}
                    </div>
                </div>
            ))}
        </div>
    )
}
