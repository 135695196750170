/**
 * Converts camel case string to dashes string (for example blueColor => blue-color).
 */
export const camelCaseToDashes = (camelCase: string): string | undefined => {
    return !camelCase
        ? undefined
        : camelCase.replace(
              /[A-Z]/g,
              (capitalLetter: string) => '-' + capitalLetter.toLowerCase()
          )
}
