import { TwoColumnLayoutComponent } from '@/types/cmsGenerated'
import { renderLayout } from '@/cms_components'
import { useMemo } from 'react'
import clsx from 'clsx'

export default function Component({
    settings,
    componentContext,
}: TwoColumnLayoutComponent) {
    const { column1, column2, reverse } = settings
    const columns = useMemo(() => [column1, column2], [column1, column2])

    return (
        <div className="my-8 md:max-w-screen-xl md:mx-auto">
            <div
                className={clsx('flex gap-8', {
                    'flex-col md:flex-row': !reverse,
                    'flex-col-reverse md:flex-row-reverse': reverse,
                })}
            >
                {columns.map((column, indexCol) => (
                    <div
                        key={indexCol}
                        className="md:w-1/2 px-8 flex flex-col gap-8"
                    >
                        {column?.map((rowItems, indexRow) => (
                            <div
                                key={indexRow}
                                className="flex justify-start gap-4"
                            >
                                {renderLayout(rowItems.value, componentContext)}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    )
}
