'use client'
import LinkArray, { LinkArrayProps } from '@/components/LinkArray'
import { useEffect, useMemo, useState } from 'react'
import { usePathname } from 'next/navigation'

type NavbarMenuProps = {
    links: LinkArrayProps['links']
}

const desktopButtonStyles = (baseClassNames: string) =>
    `${baseClassNames} duration-300 transition-[border] ease-linear hover:border-b-[1px] hover:border-blue-dark hover:no-underline border-t-0 border-l-0 border-r-0 rounded-none text-base line-height leading-7 min-h-0 h-9 px-0`
const desktopButtonListStyles = (baseClassNames: string) =>
    `${baseClassNames} gap-4 mx-4`

const getActiveLinkIndex = (
    links: NavbarMenuProps['links'],
    pathname: string
) => {
    const currentSection = '/' + pathname.split('/')[1]

    let activeSectionIndex = -1
    for (let i = 0; i < links.length; i++) {
        if (links[i].link === currentSection) {
            activeSectionIndex = i
            break
        }
    }

    return activeSectionIndex
}

const NavbarMenu = ({ links }: NavbarMenuProps) => {
    const pathname = usePathname()
    const [activeSectionIndex, setActiveSectionIndex] = useState(
        getActiveLinkIndex(links, pathname)
    )

    useEffect(() => {
        const newIndex = getActiveLinkIndex(links, pathname)
        setActiveSectionIndex(newIndex)
    }, [links, pathname])

    return (
        <LinkArray
            links={links}
            classNames={{
                desktopButton: desktopButtonStyles,
                desktopButtonList: desktopButtonListStyles,
            }}
            activeLinkIndex={activeSectionIndex}
            responsive={true}
        />
    )
}

export default NavbarMenu
