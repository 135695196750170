import { ContactsTableComponent } from '@/types/cmsGenerated'
import { GroupedTable, Group } from '@/components/GroupedTable'
import { renderLayout } from '@/cms_components'
import { getContactInfoById } from '@/api/api'

async function fetchData(
    departments: ContactsTableComponent['settings']['departments'],
    componentContext: ContactsTableComponent['componentContext']
) {
    const result: Group[] = await departments.reduce(
        async (accPromise, val) => {
            const contacts = await Promise.all(
                val?.value.contacts?.map((contact) =>
                    getContactInfoById(contact._id, componentContext.lang)
                ) || []
            )

            const acc = await accPromise
            return [
                ...acc,
                {
                    groupName: val.value.department,
                    values: contacts.map((contact) =>
                        renderLayout(contact.data.layout, componentContext)
                    ),
                },
            ]
        },
        Promise.resolve<Group[]>([])
    )
    return result
}

export default async function Component({
    settings,
    componentContext,
}: ContactsTableComponent) {
    const { title, departments } = settings
    const downloadedDepartmets = await fetchData(departments, componentContext)

    return <GroupedTable title={title} groups={downloadedDepartmets} />
}
