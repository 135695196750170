// TODO unify clients

import 'isomorphic-fetch'
const endpoint = process.env.CMS_API_URL || ''
const token = process.env.CMS_API_TOKEN || ''
const fetchErrorMessage =
    'Failed to fetch data, please check the url, where you are sending the request to.'

class GraphQLClient {
    apiUrl: string
    settings: RequestInit

    constructor(apiUrl: string, settings: RequestInit) {
        this.apiUrl = apiUrl
        this.settings = settings
    }

    async request(gQuery: string, variables?: { [key: string]: string }) {
        const requestBody = {
            query: gQuery,
            variables,
        }

        const response = await fetch(this.apiUrl, {
            method: 'POST',
            cache: 'no-store',
            ...this.settings,
            body: JSON.stringify(requestBody),
        })

        if (!response.ok) {
            throw new Error(fetchErrorMessage)
        }

        const json = await response.json()

        return json.data
    }
}

export const graphQLClient = new GraphQLClient(endpoint + '/graphql/query', {
    headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
    },
})

export class RawClient {
    apiUrl: string
    settings: RequestInit

    constructor(apiUrl: string, settings: RequestInit) {
        this.apiUrl = apiUrl
        this.settings = settings
    }

    async request<T>(
        endpoint: string,
        method: 'GET' | 'POST' = 'GET',
        body?: string
    ): Promise<T> {
        const fullEndpoint = this.apiUrl + endpoint

        const response = await fetch(fullEndpoint, {
            method,
            cache: 'no-store',
            body,
            ...this.settings,
        })

        if (!response.ok) {
            throw new Error(fetchErrorMessage)
        }

        const json = await response.json()

        return json
    }
}

export const rawClient = new RawClient(endpoint, {
    headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
    },
})
