'use client'
import { ComponentWrap } from '@/components/ComponentWrap'
import { LinkArrayComponent } from '@/types/cmsGenerated'
import NavbarMenu from '@/components/NavbarMenu'
import { mapFromValue } from '@/utils/functions/mapFromValue'

export default function Component({ settings }: LinkArrayComponent) {
    const { links } = settings

    return (
        <ComponentWrap>
            <NavbarMenu links={mapFromValue(links)} />
        </ComponentWrap>
    )
}
