import { Button, ButtonProps } from '@/components/Button/index'
import React from 'react'
import { generateImagePath } from '@/utils/functions/helpers'
import Image from '@/components/Image'

type ImageData = {
    path: string
    alt?: string
}

type IconButtonProps = {
    imageData: ImageData
    url?: string
    active?: boolean
    onClick?: (event: Event) => void
    type?: ButtonProps['type']
    size?: ButtonProps['size']
    rootClasses?: string
}

export const IconButton = ({
    type = 'circle',
    size = 'sm',
    imageData,
    ...props
}: IconButtonProps) => {
    return (
        <Button type={type} color={'ghost'} size={size} {...props}>
            <Image
                path={generateImagePath(imageData?.path)}
                alt={imageData?.alt || ''}
            />
        </Button>
    )
}
