import { ComponentWrap } from '@/components/ComponentWrap'
import { Heading } from '@/components/Heading'
import { HeadingComponent } from '@/types/cmsGenerated'

export default function Component({ settings }: HeadingComponent) {
    const { text, tag, align } = settings
    return (
        <ComponentWrap>
            <Heading text={text} tag={tag || 'h1'} align={align || 'left'} />
        </ComponentWrap>
    )
}
