'use client'
import { useMemo } from 'react'
import { CMSComponent } from '@/types/cmsTypesGeneration/cmsStatic'
import { ComponentContext } from '@/types/cmsConfig'

type Props = {
    children: JSX.Element
    component: CMSComponent
    context: ComponentContext
}

export default function DebugWrapper({ children, component, context }: Props) {
    const debugString = useMemo(() => {
        if (context.collectionId) {
            return `${context.collectionSingletonName}/${context.collectionId}/${component.component}/${component.settings.id}`
        } else {
            return `${context.collectionSingletonName}/${component.component}/${component.settings.id}`
        }
    }, [context, component])

    return (
        <>
            <DebugInfo
                dataId={component.settings.id || undefined}
                dataPath={debugString}
            />
            {children}
        </>
    )
}

const DebugInfo = ({
    dataId,
    dataPath,
}: {
    dataId?: string
    dataPath?: string
}) => {
    return <span data-id={dataId} data-path={dataPath} className="hidden" />
}
