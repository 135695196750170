import Image from '@/components/Image'
import { Heading } from '@/components/Heading'
import { Button } from '@/components/Button'
import { DirectionButton } from '@/components/Button/DirectionButton'

type Props = {
    title: string
    text: string
    imagePath: string
    imageAlt: string
    children: null | JSX.Element
    sectors: string[]
    nextClick: () => void
    prevClick: () => void
}

export const CompanyInfo = ({
    title,
    text,
    imagePath,
    imageAlt,
    children,
    sectors,
    nextClick,
    prevClick,
}: Props) => {
    return (
        <div className="flex items-center justify-around flex-col lg:flex-row">
            <div className="w-full md:w-1/2 flex justify-end mx-0 lg:mx-20">
                <Image path={imagePath} alt={imageAlt} />
            </div>
            <div className="w-full md:w-1/2 flex items-center justify-start">
                <div className="flex flex-col items-start justify-around mt-10 lg:mt-10 mx-0 mr-4 lg:mx-4 xl:mx-20 text-blue-dark w-auto lg:w-9/12 xl:w-1/2">
                    <Heading text={title} tag="h3" />
                    <div className="py-8 text-blue-for-body-text">
                        {sectors.length > 0 && (
                            <div className="pb-8 text-blue-dark">
                                {sectors
                                    .map((sector) => `•\u00A0${sector}`)
                                    .join(' ')}
                            </div>
                        )}
                        <div dangerouslySetInnerHTML={{ __html: text }} />
                    </div>
                    {children}
                </div>
                <div className="flex flex-col no-grow gap-4">
                    <DirectionButton direction="up" onClick={prevClick} />
                    <DirectionButton direction="down" onClick={nextClick} />
                </div>
            </div>
        </div>
    )
}
