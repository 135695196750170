import { ComponentWrap } from '@/components/ComponentWrap'
import { Modal } from '@/components/Modal'
import { ButtonComponent, ModalComponent } from '@/types/cmsGenerated'
import { renderLayout } from '..'

export default function Component({
    settings,
    children,
    componentContext,
}: ModalComponent) {
    const { ButtonText, ButtonType, ButtonVariant } = settings
    return (
        <ComponentWrap>
            <Modal
                button={renderLayout(
                    [
                        {
                            component: 'button',
                            settings: {
                                text: ButtonText,
                                variant: ButtonVariant,
                                type: ButtonType,
                                id: '',
                                class: '',
                                style: '',
                            },
                        } as Omit<ButtonComponent, 'componentContext'>,
                    ],
                    componentContext
                )}
            >
                {renderLayout(children, componentContext)}
            </Modal>
        </ComponentWrap>
    )
}
