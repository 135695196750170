import React from 'react'
import { TableDataProps } from '../types'

const TableHeading = ({ tableData }: TableDataProps) => {
    const theadStyle = 'border-b font-medium dark:border-neutral-500'
    return (
        <thead className={theadStyle}>
            <tr>
                {Object.keys(tableData[0]).map((heading, idx) => (
                    <th
                        key={`${heading + idx}`}
                        scope="col"
                        className="px-6 py-4"
                    >
                        {heading}
                    </th>
                ))}
            </tr>
        </thead>
    )
}

export default TableHeading
