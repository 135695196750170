import { ComponentWrap } from '@/components/ComponentWrap'
import { Text, TextColorkeys, TextVariantKeys } from '@/components/Text'
import { TextComponent } from '@/types/cmsGenerated'
import { camelCaseToDashes } from '@/utils/functions/camelCaseToDashes'

export default function Component({ settings }: TextComponent) {
    const { text, color, variant } = settings

    return (
        <ComponentWrap>
            <Text
                text={text || ''}
                color={
                    color
                        ? (camelCaseToDashes(color) as TextColorkeys)
                        : undefined
                }
                variant={variant ? (variant as TextVariantKeys) : undefined}
            />
        </ComponentWrap>
    )
}
