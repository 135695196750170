import { StatsListComponent } from '@/types/cmsGenerated'
import StatsCounter from '@/components/StatsCounter/StatsCounter'

export default function Component({ settings }: StatsListComponent) {
    const { stats, title, speed } = settings

    return (
        <div className="flex flex-col gap-4 md:max-w-screen-xl md:mx-auto mx-4 my-8">
            <div className="flex gap-8 items-center">
                <div className="eyebrown min-w-fit text-blue-dark">{title}</div>
                <div className="w-full border-b-2 border-blue-light" />
            </div>
            <div className="flex flex-wrap justify-between">
                {stats.map((stat, index) => (
                    <StatsCounter
                        key={index}
                        maxValue={stat.value.maxValue}
                        unit={stat.value.unit}
                        description={stat.value.description}
                        speed={speed}
                    />
                ))}
            </div>
        </div>
    )
}
