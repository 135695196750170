import { ComponentWrap } from '@/components/ComponentWrap'
import { Tile } from '@/components/Tile'
import Image from '@/components/Image'
import { TileComponent } from '@/types/cmsGenerated'
import { Heading } from '@/components/Heading'
import { Text } from '@/components/Text'
import { generateImagePath } from '@/utils/functions/helpers'
import { Button } from '@/components/Button'
import { getLinkWrapper } from '@/components/LinkWrapper'
import { DirectionButton } from '@/components/Button/DirectionButton'

export default function Component({ settings }: TileComponent) {
    const {
        tile1_url,
        tile1_image,
        tile1_button_url,
        tile1_button_text,
        tile1_heading,
        tile1_text,
        tile1_logo,
        tile1_logo_url,
        tile2_url,
        tile2_heading,
        tile2_button_url,
        tile2_text,
        tile3_url,
        tile3_heading,
        tile3_button_url,
        tile3_text,
    } = settings
    const LinkWrapper = getLinkWrapper(tile1_logo_url)

    return (
        <ComponentWrap>
            <div className="bg-gray-light py-20">
                <div className="flex flex-col gap-8 md:max-w-screen-xl md:mx-auto">
                    <Tile url={tile1_url}>
                        <div className="flex flex-wrap justify-between">
                            <div className="p-8 flex flex-col justify-between md:w-1/3">
                                <Heading text={tile1_heading} tag="h5" />
                                <Text text={tile1_text} />
                            </div>
                            <div className="flex justify-center items-end">
                                <LinkWrapper>
                                    <Image
                                        width="300"
                                        height="300"
                                        path={generateImagePath(
                                            tile1_image?.path
                                        )}
                                        alt={tile1_image?.title || ''}
                                    />
                                </LinkWrapper>
                            </div>
                            <div className="flex items-center gap-8 p-8">
                                <Image
                                    path={generateImagePath(tile1_logo?.path)}
                                    alt={tile1_logo?.title || ''}
                                />
                                <Button
                                    rootClasses="px-8"
                                    color="secondary"
                                    outline
                                    url={tile1_button_url}
                                >
                                    {tile1_button_text}
                                </Button>
                            </div>
                        </div>
                    </Tile>
                    <div className="flex w-full gap-8 flex-wrap md:flex-nowrap">
                        <Tile rootClasses="w-full md:w-1/2" url={tile2_url}>
                            <div className="p-8 flex flex-col items-start">
                                <Heading text={tile2_heading} tag="h5" />
                                <div className="flex items-start mt-16 mb-8">
                                    <div className="w-1/3">
                                        <DirectionButton
                                            url={tile2_button_url}
                                            direction="right"
                                        />
                                    </div>
                                    <div className="w-2/3 mx-8">
                                        <Text text={tile2_text} />
                                    </div>
                                </div>
                            </div>
                        </Tile>
                        <Tile rootClasses="w-full md:w-1/2" url={tile3_url}>
                            <div className="p-8 flex flex-col items-start">
                                <Heading text={tile3_heading} tag="h5" />
                                <div className="flex items-start mt-16 mb-8">
                                    <div className="w-1/3">
                                        <DirectionButton
                                            url={tile3_button_url}
                                            direction="right"
                                        />
                                    </div>
                                    <div className="w-2/3 mx-8">
                                        <Text text={tile3_text} />
                                    </div>
                                </div>
                            </div>
                        </Tile>
                    </div>
                </div>
            </div>
        </ComponentWrap>
    )
}
