import { FragmentPlaceholderComponent } from '@/types/cmsGenerated'
import { renderLayout } from '@/cms_components'
import { getFragmentById } from '@/api/api'

export default async function Component({
    settings,
    componentContext,
}: FragmentPlaceholderComponent) {
    const { fragmentLink } = settings

    if (!fragmentLink) {
        return null
    }

    const result = await getFragmentById(
        fragmentLink._id,
        componentContext.lang
    )
    return renderLayout(result.data.layout, {
        ...componentContext,
        collectionSingletonName: result.collectionName,
        collectionId: result.data._id,
    })
}
