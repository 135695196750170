import { LayoutProp } from '@/types/cmsTypesGeneration/cmsStatic'
import { ComponentContext } from '@/types/cmsConfig'
import LayoutComponent from '@/cms_components/LayoutComponent'
import { Fragment } from 'react'

export const renderLayout = (
    layout: LayoutProp = [],
    context?: ComponentContext
) => {
    if (!layout) return null
    if (Array.isArray(layout) && layout.length) {
        const usedContext = context || {}
        return (
            <>
                {layout.map((component, index) => (
                    <Fragment key={index}>
                        <LayoutComponent
                            component={component}
                            context={Object.assign({}, usedContext)}
                        />
                    </Fragment>
                ))}
            </>
        )
    }
    return null
}
