import { Timeline_placeholderComponent } from '@/types/cmsGenerated'
import { renderLayout } from '@/cms_components'
import { getTimelineById } from '@/api/api'

export default async function Component({
    settings,
    componentContext,
}: Timeline_placeholderComponent) {
    const { layout_id } = settings

    if (!layout_id) {
        return null
    }

    const result = await getTimelineById(layout_id._id, componentContext.lang)
    return renderLayout(result.data.layout, {
        ...componentContext,
        collectionSingletonName: result.collectionName,
        collectionId: result.data._id,
    })
}
