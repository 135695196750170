'use client'
// import { ComponentWrap } from '@/components/ComponentWrap'
import {
    ProductItemComponent,
    // ProductItemsCollection,
} from '@/types/cmsGenerated'
// import { renderLayout } from '@/cms_components'
// import { useFetch } from '@/hooks/useFetch'

export default function Component({
    settings,
    componentContext,
}: ProductItemComponent) {
    const { layout } = settings
    const { searchParams } = componentContext
    // const { data, error, isLoading } = useFetch<ProductItemsCollection>(
    //     `/api/product?productId=${searchParams.productId}`
    // )

    const isLoading = true
    if (isLoading) {
        return <div>Loading...</div>
    }

    // if (error) {
    //     return <div>Data fetching error occured.</div>
    // }
    // if (data) {
    //     return (
    //         <ComponentWrap>
    //             {renderLayout(layout, {
    //                 ...componentContext,
    //                 name: data.name,
    //                 type: data.type,
    //                 description: data.description,
    //                 pictures: JSON.stringify(data.pictures),
    //             })}
    //         </ComponentWrap>
    //     )
    // }

    return <div>No data found</div>
}
