import { Tiles_placeholderComponent } from '@/types/cmsGenerated'
import { getTilesById } from '@/api/api'
import { renderLayout } from '@/cms_components'

export default async function Component({
    settings,
    componentContext,
}: Tiles_placeholderComponent) {
    const { tiles_layout } = settings
    const result = await getTilesById(tiles_layout, componentContext.lang)

    return renderLayout(result.data.tiles_layout, {
        ...componentContext,
        collectionSingletonName: result.collectionName,
        collectionId: result.data._id,
    })
}
