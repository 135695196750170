import { ComponentWrap } from '@/components/ComponentWrap'
import { DropdownLink } from '@/components/DropdownLink'
import { DropdownLinkComponent } from '@/types/cmsGenerated'

export default function Component({
    settings,
    componentContext,
}: DropdownLinkComponent) {
    const { text, url, dropdownLinks } = settings
    const component = (
        <DropdownLink text={text} url={url} dropdownLinks={dropdownLinks} />
    )
    return <ComponentWrap>{component}</ComponentWrap>
}
