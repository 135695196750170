import { Blockquote } from '@/components/Blockquote'
import { ComponentWrap } from '@/components/ComponentWrap'
import { BlockquoteComponent } from '@/types/cmsGenerated'

export default function Component({
    settings,
    componentContext,
}: BlockquoteComponent) {
    const { author_image } = settings
    return (
        <ComponentWrap>
            <Blockquote {...settings} author_image={author_image?.path} />
        </ComponentWrap>
    )
}
