import { CMSComponent } from '@/types/cmsTypesGeneration/cmsStatic'
import Heading from './Heading'
import Section from './Section'
import Text from './Text'
import Grid from './Grid'
import Blockquote from './Blockquote'
import Button from './Button'
import DropdownLink from './DropdownLink'
import Html from './Html'
import Divider from './Divider'
import Spacer from './Spacer'
import Container from './Container'
import Table from './Table'
import Modal from './Modal'
import Asset from './Asset'
import Accordion from './Accordion'
import StatsCounter from './StatsCounter'
import { ComponentToProps } from '@/types/cmsConfig'
import ContactForm from './ContactForm'
import NavbarMenu from './NavbarMenu'
import ProductItem from './ProductItem'
import Hero from './Hero'
import Tile from './Tile'
import TilesPlaceholder from './TilesPlaceholder'
import Logo from './Logo'
import LinkArray from './LinkArray'
import LanguageSwitcher from './LanguageSwitcher'
import MapLayoutComponent from './MapLayoutComponent'
import TimelineLayout from './TimelineLayout'
import TimelinePlaceholder from './TimelinePlaceholder'
import TwoColumnLayout from './TwoColumnLayout'
import ReferenceBox from './ReferenceBox'
import InfoBoxPlaceholder from './InfoBoxPlaceholder'
import InfoBox from './InfoBox'
import NavigationCard from './NavigationCard'
import TabsLayout from './TabsLayout'
import IconButton from './IconButton'
import ImageStrip from './ImageStrip'
import ImageStripPlaceholder from './ImageStripPlaceholder'
import ThreeColumnLayout from './ThreeColumnLayout'
import StatsList from './StatsList'
import ImageGrid from './ImageGrid'
import AboutUsTopBox from './AboutUsTopBox'
import Career from './Career'
import Form from './Form'
import GradientBackgroundHeader from './GradientBackgroundHeader'
import FragmentPlaceholder from './FragmentPlaceholder'
import ValuesTable from './ValuesTable'
import ContactsTable from './ContactsTable'
import PersonalContactInfo from './PersonalContactInfo'

const componentMap: Record<
    CMSComponent['component'],
    React.FunctionComponent<any>
> = {
    // todo any
    heading: Heading,
    Career: Career,
    Form: Form,
    asset: Asset,
    section: Section,
    text: Text,
    grid: Grid,
    Blockquote: Blockquote,
    button: Button,
    dropdownLink: DropdownLink,
    html: Html,
    divider: Divider,
    IconButton: IconButton,
    spacer: Spacer,
    Container: Container,
    ContactForm: ContactForm,
    Table: Table,
    Modal: Modal,
    accordion: Accordion,
    StatsCounter: StatsCounter,
    NavbarMenu: NavbarMenu,
    ProductItem: ProductItem,
    Hero: Hero,
    Tile: Tile,
    tiles_placeholder: TilesPlaceholder,
    Logo: Logo,
    LinkArray: LinkArray,
    NavigationCard: NavigationCard,
    LanguageSwitcher: LanguageSwitcher,
    MapLayout: MapLayoutComponent,
    TwoColumnLayout: TwoColumnLayout,
    TimelineLayout: TimelineLayout,
    timeline_placeholder: TimelinePlaceholder,
    reference_box: ReferenceBox,
    info_box_placeholder: InfoBoxPlaceholder,
    info_box: InfoBox,
    TabsLayout: TabsLayout,
    image_strip: ImageStrip,
    image_strip_placeholder: ImageStripPlaceholder,
    ThreeColumnLayout: ThreeColumnLayout,
    StatsList: StatsList,
    ImageGrid: ImageGrid,
    About_Us_Top_Box: AboutUsTopBox,
    GradientBackgroundHeader: GradientBackgroundHeader,
    FragmentPlaceholder: FragmentPlaceholder,
    ValuesTable: ValuesTable,
    ContactsTable: ContactsTable,
    PersonalContactInfo: PersonalContactInfo,
}

export const selectComponent = (componentName: CMSComponent['component']) => {
    if (componentMap[componentName]) return componentMap[componentName]

    return function Component({
        settings,
        componentContext,
    }: ComponentToProps<CMSComponent>) {
        console.error(`ERROR: UNMAPPED COMPONENT ${componentName}`)

        if (!process.env.SHOW_UNMAPPED_COMPONENT_PLACEHOLDER) {
            return null
        }

        return (
            <div
                style={{
                    border: '1px solid black',
                    padding: '8px',
                    margin: '8px',
                    wordWrap: 'break-word',
                }}
            >
                <span style={{ color: 'red', fontSize: '200%' }}>
                    UNMAPPED COMPONENT {componentName}
                </span>
                <br />
                {'settings:'}
                {JSON.stringify(settings)}
                <br />
                {'context:'}
                {JSON.stringify(componentContext)}
            </div>
        )
    }
}
