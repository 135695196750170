import { Container } from '@/components/Container'
import { renderLayout } from '@/cms_components'
import { CareerComponent } from '@/types/cmsGenerated'
import { ComponentWrap } from '@/components/ComponentWrap'
import { Heading } from '@/components/Heading'
import { Text } from '@/components/Text'
import { ReactElement } from 'react'
import { DirectionButton } from '@/components/Button/DirectionButton'

export default function Component({ settings }: CareerComponent) {
    const {
        header,
        careerDescription,
        contactDescription,
        contactButtonLayout,
        careers,
        careersPositionColumnLabel,
        careersLocationColumnLabel,
    } = settings

    return (
        <ComponentWrap>
            <div className="max-w-[904px] w-full mx-auto my-8 flex flex-col">
                <div className="flex flex-col self-center items-center mb-16 mx-4 max-w-[546px]">
                    <Heading
                        tag="h5"
                        className="mb-4 text-center"
                        text={header}
                    />
                    <Text className="text-center" text={careerDescription} />
                </div>
                <table className="table">
                    {/* head */}
                    <thead>
                        <tr className="border-neutral border-b">
                            <th>{careersPositionColumnLabel}</th>
                            <th>{careersLocationColumnLabel}</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {/* row  */}
                        {careers.map((careerItem, index: number) => (
                            <tr
                                key={index}
                                className="border-neutral border-b whitespace-normal"
                            >
                                <td>{careerItem.value.position}</td>
                                <td>{careerItem.value.location}</td>
                                <th className="flex justify-end">
                                    <DirectionButton
                                        direction="right"
                                        url={careerItem.value.link}
                                    />
                                </th>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="flex flex-col items-center mt-16 mx-4 max-w-[546px] self-center">
                    <Text
                        className="text-center mb-6"
                        text={contactDescription}
                    />
                    {renderLayout(contactButtonLayout) as ReactElement}
                </div>
            </div>
        </ComponentWrap>
    )
}
