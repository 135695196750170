'use client'
import { useState, useEffect } from 'react'

type StatsCounterSettings = {
    maxValue: string
    unit: string
    description: string
    speed: string
}

const StastCounter = ({
    maxValue,
    speed,
    unit,
    description,
}: StatsCounterSettings) => {
    const maxValueToNumber = Number(maxValue)
    const countingSpeed = speed ? Number(speed) * 1000 : 5000
    const steps = speed ? Number(speed) * 100 : 50

    const [count, setCount] = useState(0)
    const stepTime = countingSpeed / steps

    useEffect(() => {
        let start = 0
        const increment = maxValueToNumber / steps
        const timer = setInterval(() => {
            start += increment
            if (start >= maxValueToNumber) {
                setCount(maxValueToNumber)
                clearInterval(timer)
            } else {
                setCount(start)
            }
        }, stepTime)
        return () => clearInterval(timer)
    }, [maxValueToNumber, stepTime, steps])

    const StyledCounter = 'm-4 p-4 text-center'
    const StyledNumber = 'font-semibold text-5xl'
    const StyledDescription = 'mt-2'

    return (
        <div className={StyledCounter}>
            <span className={StyledNumber}>
                {count.toFixed()}
                {unit}
            </span>
            <div className={StyledDescription}>{description}</div>
        </div>
    )
}

export default StastCounter
