import { Spacer } from '@/components/Spacer'
import { ComponentWrap } from '@/components/ComponentWrap'
import { SpacerComponent } from '@/types/cmsGenerated'

export default function Component({ settings }: SpacerComponent) {
    const { size, horizontal } = settings
    return (
        <ComponentWrap>
            <Spacer size={size || 'medium'} horizontal={horizontal} />
        </ComponentWrap>
    )
}
