import Image from '../Image'
import { generateImagePath } from '@/utils/functions/helpers'

export type ImageType = {
    src: string
    alt?: string
}

interface Props {
    id?: string
    images: ImageType[]
}
export default function ImageStrip({ images }: Props) {
    return (
        <div className="flex gap-4 h-40 sm:h-48 md:h-60 lg:h-[360px] overflow-hidden">
            {images.map((img) => (
                <Image
                    key={img.src + (img.alt ?? '')}
                    path={generateImagePath(img.src)}
                    alt={img.alt ?? ''}
                    className="w-full h-full object-cover"
                />
            ))}
        </div>
    )
}
