import { Button } from '@/components/Button'
import { ComponentWrap } from '@/components/ComponentWrap'
import { ButtonComponent } from '@/types/cmsGenerated'
import React from 'react'
import clsx from 'clsx'
import { Direction, DirectionButton } from '@/components/Button/DirectionButton'

export default function Component({ settings }: ButtonComponent) {
    const { url, text, variant, type, print, openInNewWindow } = settings

    if (variant?.startsWith('rounded')) {
        let direction: Direction = 'down'
        if (variant === 'rounded-up') {
            direction = 'up'
        }
        if (variant === 'rounded-left') {
            direction = 'left'
        }
        if (variant === 'rounded-right') {
            direction = 'right'
        }

        return (
            <ComponentWrap>
                <DirectionButton direction={direction} url={url} />
            </ComponentWrap>
        )
    }

    const pickVariant = (variant: string | null) => {
        if (variant === 'link' || variant === 'gray-link') {
            return 'link'
        }
        return variant === 'primary' || variant === 'secondary'
            ? variant
            : undefined
    }

    return (
        <ComponentWrap>
            <Button
                url={url}
                rootClasses={clsx({
                    'text-blue-dark text-base': variant === 'link',
                    'text-gray-middle p-0 h-auto min-h-0 no-underline hover:no-underline text-base leading-none':
                        variant === 'gray-link',
                    'border-neutral': variant === 'gray-outline',
                })}
                color={pickVariant(variant)}
                outline={variant === 'outline' || variant === 'gray-outline'}
                type={!type || type === 'normal' ? undefined : type}
                print={print}
                openInNewWindow={openInNewWindow}
            >
                {text}
            </Button>
        </ComponentWrap>
    )
}
