import clsx from 'clsx'
import { Heading } from '../Heading'
import Image from '@/components/Image'
import { generateImagePath } from '@/utils/functions/helpers'

export type BlockquoteProps = {
    quote_body: string
    body_type?: 'small-quote' | 'big-quote' | '' | null
    author: string
    className?: string
    blockquote_heading: string
    heading_type?: 'title' | 'big-quote' | '' | null
    author_image: string | undefined
    author_job_title: string
    profile_align: 'Left' | 'Right' | 'Center' | '' | null
}

export const Blockquote = ({
    quote_body,
    body_type,
    author,
    className,
    blockquote_heading,
    heading_type,
    author_job_title,
    author_image,
    profile_align,
}: BlockquoteProps) => {
    const quoteBoxStyle =
        'w-full flex flex-col justify-center text-center py-3 px-4 h-full'
    const textAlignClass =
        profile_align === 'Left'
            ? 'text-left'
            : profile_align === 'Center'
            ? 'text-center'
            : profile_align === 'Right'
            ? 'text-right'
            : ''

    return (
        <div className={clsx(quoteBoxStyle, className)}>
            {blockquote_heading && (
                <Heading
                    tag="h3"
                    text={blockquote_heading}
                    className={clsx(
                        {
                            'text-[20px] leading-[26px] text-blue-dark uppercase mb-8 mx-auto':
                                heading_type === 'title',
                            'mb-8 bodyTitle text-blue-dark max-w-[800px] mx-auto':
                                heading_type === 'big-quote',
                        },
                        textAlignClass
                    )}
                />
            )}
            {quote_body && (
                <p
                    className={clsx(
                        {
                            'text-base font-light flex-grow':
                                body_type === 'small-quote',
                            'bodyTitle text-blue-dark max-w-[800px] mx-auto':
                                body_type === 'big-quote',
                        },
                        textAlignClass
                    )}
                >
                    {quote_body}
                </p>
            )}
            <div
                className={clsx(
                    'flex items-center gap-4',
                    profile_align === 'Left'
                        ? 'justify-start'
                        : profile_align === 'Center'
                        ? 'justify-center'
                        : profile_align === 'Right'
                        ? 'justify-end'
                        : '',
                    textAlignClass,
                    author_image || author ? 'mt-16' : ''
                )}
            >
                {author_image && (
                    <Image
                        className="w-16 h-16 rounded-full"
                        path={generateImagePath(author_image)}
                        alt={author}
                    />
                )}
                {author && (
                    <div className="flex flex-col mr-4 text-left">
                        <span className="text-md font-bold">{author}</span>
                        {author_job_title && <span>{author_job_title}</span>}
                    </div>
                )}
            </div>
        </div>
    )
}
