import { ReactNode } from 'react'
import clsx from 'clsx'
import { generateImagePath } from '@/utils/functions/helpers'

type MarginLevel = 'none' | 'small' | 'medium' | 'large'

type Direction = 'left' | 'right' | 'top' | 'bottom'

const smallMarginClasses: { [key in Direction]: string } = {
    left: 'ml-2',
    right: 'mr-2',
    top: 'mt-2',
    bottom: 'mb-2',
}

const mediumMarginClasses: { [key in Direction]: string } = {
    left: 'ml-6',
    right: 'mr-6',
    top: 'mt-6',
    bottom: 'mb-6',
}

const largeMarginClasses: { [key in Direction]: string } = {
    left: 'ml-12',
    right: 'mr-12',
    top: 'mt-12',
    bottom: 'mb-12',
}

type ContainerWithChildren = {
    children: ReactNode
    fluid?: boolean
    backgroundColor?: string
    spread?: string
    backgroundImage?: string
    bgOpacity?: string
    bgImagePosition?: 'stretch' | 'repeat'
    flow?: 'row' | 'column'
    justify?:
        | 'normal'
        | 'start'
        | 'end'
        | 'center'
        | 'spaceBetween'
        | 'spaceAround'
        | 'spaceEvenly'
        | 'stretch'
    align?: 'start' | 'end' | 'center' | 'stretch'
    fullWidth?: boolean
    marginLeft?: MarginLevel
    marginRight?: MarginLevel
    marginTop?: MarginLevel
    marginBottom?: MarginLevel
}

export const Container = ({
    fluid,
    children,
    backgroundColor,
    spread,
    backgroundImage,
    bgOpacity,
    bgImagePosition,
    flow,
    justify,
    align,
    fullWidth,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
}: ContainerWithChildren) => {
    const bgImg = generateImagePath(backgroundImage)
    const cmsSpread = !!spread ? spread : '20'
    const cmsOpacityResolver = !!bgOpacity ? bgOpacity : 1
    const cmsImageResolver = !!backgroundImage ? `url(${bgImg})` : 'none'
    const cmsBgColorResolver = !!backgroundColor
        ? backgroundColor
        : 'transparent'
    const cmsBgRepeatResolver =
        !!bgImagePosition && bgImagePosition === 'repeat'
            ? 'repeat'
            : 'no-repeat'
    const cmsBgSizeResolver =
        !!bgImagePosition && bgImagePosition === 'stretch'
            ? 'cover'
            : `${cmsSpread}px`

    const cmsStyles = {
        backgroundImage: cmsImageResolver,
        backgroundColor: cmsBgColorResolver,
        opacity: cmsOpacityResolver,
        backgroundRepeat: cmsBgRepeatResolver,
        backgroundPosition: 'center',
        backgroundSize: cmsBgSizeResolver,
    }

    const fluidContainerStyle = 'md:max-w-screen-xl md:mx-auto'
    const flexContainerStyle =
        flow === 'row' ? 'flex flex-row' : 'flex flex-col'
    const justifyStyle = getJustifyClass(justify)
    const alignStyle = getAlignClass(align)
    const marginLeftStyle = getMarginClass('left', marginLeft)
    const marginRightStyle = getMarginClass('right', marginRight)
    const marginTopStyle = getMarginClass('top', marginTop)
    const marginBottomStyle = getMarginClass('bottom', marginBottom)

    return (
        <div
            style={cmsStyles}
            className={clsx(
                marginLeftStyle,
                marginRightStyle,
                marginTopStyle,
                marginBottomStyle,
                cmsBgColorResolver,
                flexContainerStyle,
                justifyStyle,
                alignStyle,
                {
                    ['w-full']: fullWidth,
                    [fluidContainerStyle]: fluid,
                }
            )}
        >
            {children}
        </div>
    )
}

const getMarginClass = (direction: Direction, level?: MarginLevel) => {
    if (level === 'small') {
        return smallMarginClasses[direction]
    }
    if (level === 'medium') {
        return mediumMarginClasses[direction]
    }
    if (level === 'large') {
        return largeMarginClasses[direction]
    }

    return ''
}

const getJustifyClass = (value: ContainerWithChildren['justify']) => {
    if (value === 'normal') {
        return 'justify-normal'
    }
    if (value === 'start') {
        return 'justify-start'
    }
    if (value === 'end') {
        return 'justify-end'
    }
    if (value === 'center') {
        return 'justify-center'
    }
    if (value === 'spaceBetween') {
        return 'justify-between'
    }
    if (value === 'spaceAround') {
        return 'justify-around'
    }
    if (value === 'spaceEvenly') {
        return 'justify-evenly'
    }
    if (value === 'stretch') {
        return 'justify-stretch'
    }
    return ''
}

const getAlignClass = (value: ContainerWithChildren['align']) => {
    if (value === 'start') {
        return 'items-start'
    }
    if (value === 'end') {
        return 'items-end'
    }
    if (value === 'center') {
        return 'items-center'
    }
    if (value === 'stretch') {
        return 'items-stretch'
    }
    return ''
}
