import clsx from 'clsx'

type Props = {
    children?: JSX.Element
    url?: string
    rootClasses?: string
}

export const Tile = ({ children, rootClasses }: Props) => {
    if (!children) {
        return null
    }
    return (
        <div className={clsx('bg-base-100/80 backdrop-blur', rootClasses)}>
            {children}
        </div>
    )
}
