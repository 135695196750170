'use client'
import { NavigationCardProps } from '@/components/NavigationCard/types'
import {
    useNavigationCard,
    useNavigationCardIframe,
} from '@/components/NavigationCard/useNavigationCard'
import { ContactInfo } from '@/components/ContactInfo'
import { Button } from '@/components/Button'

export const NavigationCardMapTop = ({
    address,
    mapCoordinates,
    shippingHours,
    phoneForLink,
    phoneText,
    email,
    additionalContainerContent,
    additionalOuterContent,
    companyVatId,
    companyTaxId,
    rootClasses = '',
    contactInfoLabel,
    companyInfoLabel,
    addressLabel,
    phoneLabel,
    shippingHoursLabel,
    companyVatIdLabel,
    companyTaxIdLabel,
    emailLabel,
    headingLabel,
}: NavigationCardProps): JSX.Element => {
    const { mapBboxCoordinates, mapMarkerCoordinates } =
        useNavigationCard(mapCoordinates)

    const iframe = useNavigationCardIframe({
        mapBboxCoordinates,
        mapMarkerCoordinates,
        width: '100%',
        height: '100%',
    })

    return (
        <div className={`bg-gray-light w-full mt-44 ${rootClasses}`}>
            <div
                className={
                    'relative bottom-44 flex flex-col items-start lg:items-center'
                }
            >
                <div
                    className={`max-w-[1296px] h-64 px-0 w-full md:h-80 lg:h-[460px] sm:px-24`}
                >
                    {iframe}
                </div>
                <div
                    className={'flex pt-20 gap-20 flex-col md:flex-row sm:px-6'}
                >
                    <div
                        className={
                            'pl-4 md:pl-0 relative bottom-44 hidden lg:block'
                        }
                    >
                        {additionalContainerContent}
                    </div>
                    <div className={'pl-2.5 sm:pl-4 '}>
                        <ContactInfo
                            address={address}
                            phoneForLink={phoneForLink}
                            phoneText={phoneText}
                            shippingHours={shippingHours}
                            email={email}
                            headingLabel={headingLabel}
                            variant={'navigationCardMapTop'}
                            rootClasses={'!w-auto'}
                            companyTaxId={companyTaxId}
                            companyVatId={companyVatId}
                            contactInfoLabel={contactInfoLabel}
                            companyInfoLabel={companyInfoLabel}
                            addressLabel={addressLabel}
                            phoneLabel={phoneLabel}
                            shippingHoursLabel={shippingHoursLabel}
                            companyVatIdLabel={companyVatIdLabel}
                            companyTaxIdLabel={companyTaxIdLabel}
                            emailLabel={emailLabel}
                        />
                        <div className="mt-16 w-44">
                            {additionalOuterContent}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
