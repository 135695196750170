import { ReactElement } from 'react'
import { ContactInfoProps } from '@/components/ContactInfo'

export enum NavigationCardVariant {
    MAP_LEFT = 'MAP_LEFT',
    MAP_RIGHT = 'MAP_RIGHT',
    MAP_TOP = 'MAP_TOP',
}

export type MapCoordinates = {
    bbox: {
        minLatitude: number
        minLongitude: number
        maxLatitude: number
        maxLongitude: number
    }
    marker: {
        latitude: number
        longitude: number
    }
}

export type NavigationCardProps = Omit<ContactInfoProps, 'variant'> & {
    address: string
    mapCoordinates: MapCoordinates
    phoneText: string
    phoneForLink: string
    additionalContainerContent: ReactElement
    additionalOuterContent: ReactElement
    companyVatId?: string
    companyTaxId?: number
    email?: string
    rootClasses?: string
    variant?: NavigationCardVariant
    shippingHours?: string
    contactFormButtonLabel?: string
    headingLabel?: string
}
