import clsx from 'clsx'

type Props = {
    className?: string
    contentClasses?: string
    bgImageUrl?: string
    children: JSX.Element
}

export const Hero = ({
    children,
    className,
    contentClasses,
    bgImageUrl,
}: Props) => {
    return (
        <div
            className={clsx('hero', className)}
            style={{ backgroundImage: `url('${bgImageUrl}')` || 'none' }}
        >
            <div
                className={clsx(
                    'hero-content max-w-none w-full pl-0 pr-0',
                    contentClasses
                )}
            >
                {children}
            </div>
        </div>
    )
}
