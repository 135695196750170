import { ComponentWrap } from '@/components/ComponentWrap'
import LanguageSwitcher from '@/components/LanguageSwitcher'
import { DEFAULT_LANGUAGE } from '@/constants'

export default function Component() {
    return (
        <ComponentWrap>
            <LanguageSwitcher nativeLanguage={DEFAULT_LANGUAGE} />
        </ComponentWrap>
    )
}
