import { useEffect } from 'react'

export const useResizeWindow = (callback: () => void) => {
    useEffect(() => {
        window.addEventListener('resize', callback)
        callback()

        return () => {
            window.removeEventListener('resize', callback)
        }
    }, [callback])
}
