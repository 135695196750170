import { ComponentWrap } from '@/components/ComponentWrap'
import { LogoComponent } from '@/types/cmsGenerated'
import Image from '@/components/Image'
import { getLinkWrapper } from '@/components/LinkWrapper'
import { generateImagePath } from '@/utils/functions/helpers'

export default function Component({ settings }: LogoComponent) {
    const { url, asset } = settings
    const LinkWrapper = getLinkWrapper(url)
    return (
        <ComponentWrap>
            <LinkWrapper>
                <Image
                    path={generateImagePath(asset?.path) || undefined}
                    alt={asset?.title || ''}
                    className="max-h-10"
                />
            </LinkWrapper>
        </ComponentWrap>
    )
}
