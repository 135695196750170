import { renderLayout } from '..'
import { ComponentWrap } from '@/components/ComponentWrap'
import { GridColumnComponent } from '@/types/cmsGenerated'

export const GridLayout = ({
    columns,
    componentContext,
    columnsCount,
}: {
    columns: any[]
    componentContext: any
    columnsCount: number
}) => {
    const layout = generateLayout(columnsCount)
    return (
        <>
            <div className={layout}>
                {columns.map((props, index) => (
                    <GridColumn
                        key={index}
                        {...props}
                        componentContext={componentContext}
                    />
                ))}
            </div>
        </>
    )
}

const GridColumn = ({ children, componentContext }: GridColumnComponent) => {
    const content = renderLayout(children, componentContext)

    if (!content) {
        return null
    }

    return <ComponentWrap>{content}</ComponentWrap>
}

// Generate the maximum number of columns
const generateLayout = (columnsCount: number) => {
    const classes = [
        'grid row-auto',
        'sm:grid-cols-2',
        'md:grid-cols-3',
        'lg:grid-cols-4',
        'xl:grid-cols-5',
        '2xl:grid-cols-6',
    ]
    const layout = []
    // Iterate through the number of columns to add all relevant breakpoints
    for (let i = 0; i < columnsCount; i++) {
        layout.push(classes[i])
    }

    // Join the classes together
    return layout.join(' ')
}
