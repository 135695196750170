import { graphQLClient } from '@/api/client'
import { DEFAULT_LANGUAGE } from '@/constants'
import { Language } from '@/utils/language'

const langPrefix = DEFAULT_LANGUAGE === 'hu' ? 'HU ' : 'CZ '

interface NewsResultConfig {
    title: string
    title_slug: string
    _id: string
    text: string
    description: string
    date?: string
    pictureWidth?: string
    picture: {
        _id: string
        title: string
        path: string
        mime: string
    }
}

export const getMenuItems = async () => {
    const { PagesCollection } = await graphQLClient.request(`
    query GetMenuItems {
      PagesCollection {
        _id
        menuTitle
        pageSlug
        accessLevel
      }
    }
  `)
    return PagesCollection
}

export const getPageBySlug = async (slug: string, lang: string) => {
    let collectionName: string = 'CZ_PagesCollection'
    if (process.env.DEFAULT_LANGUAGE === 'hu') {
        collectionName = 'HU_PagesCollection'
    }

    const result = await graphQLClient.request(
        `
    query GetPageBySlug($slug: String, $lang: String) {
      ${collectionName}(lang: $lang, filter: { pageTitle_slug: $slug}) {
        _id
        pageContent
        accessLevel
      }
    }
  `,
        { lang, slug }
    )

    return { data: result[collectionName][0], collectionName: collectionName }
}

export const getNewsPageBySlug = async (slug: string, lang: string) => {
    let collectionName: string = 'CZ_NewsCollection'
    if (process.env.DEFAULT_LANGUAGE === 'hu') {
        collectionName = 'HU_NewsCollection'
    }
    const data = await graphQLClient.request(
        `
  query GetNewsPageBySlug($slug: String, $lang: String) {
    ${collectionName} (lang: $lang, filter: { title_slug: $slug}) {
      title
      title_slug
      _id
      text
      description
      date
      pictureWidth
      picture {
        _id
        title
        path
        mime
      }
    }
  }
  `,
        { lang, slug }
    )
    const result: NewsResultConfig = data[collectionName][0]
    return result
}

export const getContactInfoById = async (
    id: string,
    lang: Language = DEFAULT_LANGUAGE
) => {
    const collectionName: string = 'contact_infoCollection'

    const result = await graphQLClient.request(
        `
    query GetContactInfoById ($id: String, $lang: String) {
      ${collectionName}(lang: $lang, filter: {_id: $id}) {
        _id
        Name
        layout
      }
    }
  `,
        { id, lang }
    )

    return { data: result[collectionName][0], collectionName: collectionName }
}

/**
 * Get navigation card, name will be prefixed with current default language
 */
export const getNavigationCardByName = async (
    name: string,
    lang: Language = DEFAULT_LANGUAGE
) => {
    const collectionName: string = 'navigation_cardCollection'

    const result = await graphQLClient.request(
        `
    query GetNavigationCardByName ($name: String, $lang: String) {
      ${collectionName}(filter: {name: $name}, lang: $lang) {
        _id
        name
        layout
        surroundingLayout
      }
    }
  `,
        {
            name: name.startsWith(langPrefix) ? name : `${langPrefix}${name}`,
            lang,
        }
    )

    return { data: result[collectionName]?.[0], collectionName: collectionName }
}

export const getInfoBoxById = async (
    id: string,
    lang: Language = DEFAULT_LANGUAGE
) => {
    const collectionName: string = 'info_boxCollection'

    const result = await graphQLClient.request(
        `
    query GetInfoBoxById($id: String, $lang: String) {
      ${collectionName}(filter: { id: $id}, lang: $lang) {
        _id
        layout
      }
    }
  `,
        { id, lang }
    )

    return { data: result[collectionName][0], collectionName: collectionName }
}

/**
 * Get menu, name will be prefixed with current default language
 */
export const getMenuByName = async (name: string, lang: Language) => {
    const collectionName: string = 'CZ_MenuCollection'

    const result = await graphQLClient.request(
        `
    query GetMenuByName($name: String, $lang: String) {
      ${collectionName}(lang: $lang, filter: {name: $name}) {
        _id
        name
        menuItems
      }
    }
  `,
        {
            name: name.startsWith(langPrefix) ? name : `${langPrefix}${name}`,
            lang,
        }
    )

    return { data: result[collectionName][0], collectionName: collectionName }
}

export const saveCollectionItem = async (name: string, body: string) => {
    if (!name) return null
    return await graphQLClient.request(
        `
        mutation saveCollectionItem($name: String!, $data: JsonType!) {
          saveCollectionItem(name: $name, data: $data) {
            data
          }
        }
    `,
        {
            name,
            data: JSON.parse(body),
        }
    )
}

export const getBlockquoteById = async (
    id: string,
    lang: Language = DEFAULT_LANGUAGE
) => {
    const collectionName: string = 'blockquotesCollection'

    const result = await graphQLClient.request(
        `
    query GetBlockquoteById($id: String, $lang: String) {
      ${collectionName}(filter: {_id: $id}, lang: $lang) {
        _id
        name
        blockquote
      }
    }
  `,
        { id, lang }
    )

    return { data: result[collectionName][0], collectionName: collectionName }
}

export const getTilesById = async (
    layoutId: string,
    lang: Language = DEFAULT_LANGUAGE
) => {
    const collectionName: string = 'Lachner_TilesCollection'

    const result = await graphQLClient.request(
        `
    query GetTilesById($layoutId: String, $lang: String) {
      ${collectionName}(filter: {layout_id: $layoutId}, lang: $lang) {
        _id
        layout_id
        tiles_layout
      }
    }
  `,
        { layoutId, lang }
    )

    return { data: result[collectionName][0], collectionName: collectionName }
}

export const getTimelineById = async (
    id: string,
    lang: Language = DEFAULT_LANGUAGE
) => {
    console.log('lang', lang)
    const collectionName: string = 'timelineCollection'

    const result = await graphQLClient.request(
        `
    query GetTilesById($id: String, $lang: String) {
      ${collectionName}(filter: {_id: $id}, lang: $lang) {
        _id
        name
        layout
      }
    }
  `,
        { id, lang }
    )

    return { data: result[collectionName][0], collectionName: collectionName }
}

export const getFragmentById = async (
    id: string,
    lang: Language = DEFAULT_LANGUAGE
) => {
    const collectionName: string = 'PageFragmentsCollection'

    const result = await graphQLClient.request(
        `
    query GetFragmentById($id: String, $lang: String) {
      ${collectionName}(filter: {_id: $id}, lang: $lang) {
        _id
        name
        layout
      }
    }
  `,
        { id, lang }
    )

    return { data: result[collectionName][0], collectionName: collectionName }
}

export const getImageStripById = async (id: string) => {
    const collectionName: string = 'image_stripCollection'

    const result = await graphQLClient.request(
        `
    query GetImageStripById($id: String) {
      ${collectionName}(filter: {_id: $id}) {
        _id
        layout
      }
    }
  `,
        { id }
    )

    return { data: result[collectionName]?.[0], collectionName: collectionName }
}

export const getFooterDefinition = async () => {
    const collectionName: string = 'FooterSingleton'

    const { FooterSingleton } = await graphQLClient.request(`
    query GetFooterDefinition {
      ${collectionName} {
        Footer
      }
    }
  `)

    return { data: FooterSingleton, collectionName: collectionName }
}
