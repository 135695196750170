import Image from '../Image'
import { generateImagePath } from '@/utils/functions/helpers'

interface Props {
    imageUrl1?: string
    imageAlt1?: string
    imageUrl2?: string
    imageAlt2?: string
    imageUrl3?: string
    imageAlt3?: string
    imageUrl4?: string
    imageAlt4?: string
    imageUrl5?: string
    imageAlt5?: string
    imageUrl6?: string
    imageAlt6?: string
}
export default function ImageGrid({
    imageUrl1,
    imageAlt1,
    imageUrl2,
    imageAlt2,
    imageUrl3,
    imageAlt3,
    imageUrl4,
    imageAlt4,
    imageUrl5,
    imageAlt5,
    imageUrl6,
    imageAlt6,
}: Props) {
    return (
        <div className="flex gap-4 md:max-w-[856px] md:mx-auto flex-col md:flex-row">
            <div className="flex flex-col gap-4 w-full md:w-1/2">
                <div className="flex gap-4 w-full">
                    <div className="w-1/2 md:w-full">
                        <Image
                            path={generateImagePath(imageUrl1)}
                            alt={imageAlt1 ?? ''}
                            className="aspect-square object-cover"
                        />
                    </div>
                    <div className="w-1/2 md:w-full">
                        <Image
                            path={generateImagePath(imageUrl2)}
                            alt={imageAlt2 ?? ''}
                            className="aspect-square object-cover"
                        />
                    </div>
                </div>
                <Image
                    path={generateImagePath(imageUrl3)}
                    alt={imageAlt3 ?? ''}
                    className="aspect-[4/3] object-cover"
                />
            </div>
            <div className="flex flex-col gap-4 w-full md:w-1/2">
                <Image
                    path={generateImagePath(imageUrl4)}
                    alt={imageAlt4 ?? ''}
                    className="aspect-[4/3] object-cover"
                />
                <div className="flex gap-4 w-full">
                    <div className="w-1/2 md:w-full">
                        <Image
                            path={generateImagePath(imageUrl5)}
                            alt={imageAlt5 ?? ''}
                            className="aspect-square object-cover"
                        />
                    </div>
                    <div className="w-1/2 md:w-full">
                        <Image
                            path={generateImagePath(imageUrl6)}
                            alt={imageAlt6 ?? ''}
                            className="aspect-square object-cover"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
