import { TabsLayoutComponent } from '@/types/cmsGenerated'
import { Heading } from '@/components/Heading'
import { Tabs } from '@/components/Tabs'
import { getTabsDefinitionFromCms } from '@/utils/functions/helpers'

export default function Component({
    settings,
    componentContext,
}: TabsLayoutComponent) {
    const { title, subtitle, tabs } = settings

    return (
        <div className="flex flex-col items-center my-8">
            {title && (
                <div className="mb-8">
                    <Heading text={title} tag="h5" />
                </div>
            )}
            {subtitle && <div className="mb-20 body2">{subtitle}</div>}
            <Tabs tabs={getTabsDefinitionFromCms(tabs)} />
        </div>
    )
}
