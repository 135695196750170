import { selectComponent } from '@/cms_components/componentIndex'
import { CMSComponent } from '@/types/cmsTypesGeneration/cmsStatic'
import { ComponentContext } from '@/types/cmsConfig'
import DebugWrapper from './DebugWrapper'

type LayoutComponentProps = {
    component: CMSComponent
    context: ComponentContext
}

export default function LayoutComponent({
    component,
    context,
}: LayoutComponentProps) {
    const Component = selectComponent(component.component)

    if (!Component) {
        return null
    }

    // TODO create some rendering workaround for Grid so this spread is not needed and the error dissapears
    return (
        <DebugWrapper component={component} context={context}>
            <Component {...component} componentContext={context} />
        </DebugWrapper>
    )
}
