import { TableDataProps } from '../types'
import TableRow from './TableRow'

const TableBody = ({ tableData }: TableDataProps) => {
    return (
        <tbody>
            {tableData &&
                tableData.map((rowItem, idx) => (
                    <TableRow key={idx} rowItem={rowItem} />
                ))}
        </tbody>
    )
}

export default TableBody
