import { Image_strip_placeholderComponent } from '@/types/cmsGenerated'
import { renderLayout } from '@/cms_components'
import { getImageStripById, getTimelineById } from '@/api/api'

export default async function Component({
    settings,
    componentContext,
}: Image_strip_placeholderComponent) {
    const { id } = settings

    if (!id) {
        return null
    }

    const result = await getImageStripById(id._id)
    return renderLayout(result.data.layout, {
        ...componentContext,
        collectionSingletonName: result.collectionName,
        collectionId: result.data._id,
    })
}
