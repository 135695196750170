'use client'
import { NavigationCardProps } from '@/components/NavigationCard/types'
import { cardColumnBaseClasses } from '@/components/NavigationCard/constants'
import {
    useNavigationCard,
    useNavigationCardIframe,
} from '@/components/NavigationCard/useNavigationCard'
import { ContactInfo } from '@/components/ContactInfo'

/**
 * Renders the navigation card.
 * @param address an address
 * @param mapCoordinates an object providing coordinates for the map (more precisely for the bounding box and marker) is expected
 * @param shippingHours shipping hours
 * @param phoneForLink a phone number dialed by Skype
 * @param phoneText text for a phone number that will exist as a clickable link
 * @param email an email
 * @param className an className for style sheets
 */
export const NavigationCardMapRight = ({
    address,
    mapCoordinates,
    shippingHours,
    phoneForLink,
    phoneText,
    email,
    rootClasses = '',
    contactInfoLabel,
    addressLabel,
    phoneLabel,
    shippingHoursLabel,
    emailLabel,
}: NavigationCardProps): JSX.Element => {
    const { mapBboxCoordinates, mapMarkerCoordinates } =
        useNavigationCard(mapCoordinates)

    const iframe = useNavigationCardIframe({
        mapBboxCoordinates,
        mapMarkerCoordinates,
        width: '100%',
    })

    return (
        <div
            className={`flex flex-col w-full sm:flex-col md:flex-row md:pr-28  ${rootClasses}`}
        >
            <ContactInfo
                address={address}
                phoneForLink={phoneForLink}
                phoneText={phoneText}
                shippingHours={shippingHours}
                email={email}
                rootClasses={'md:w-1/2 pl-2.5 sm:pl-4'}
                contactInfoLabel={contactInfoLabel}
                addressLabel={addressLabel}
                phoneLabel={phoneLabel}
                shippingHoursLabel={shippingHoursLabel}
                emailLabel={emailLabel}
            />
            <div
                className={`${cardColumnBaseClasses} mt-6 ml-0 h-64 sm:ml-0 sm:mt-6 md:h-auto md:ml-6 md:mt-0`}
            >
                {iframe}
            </div>
        </div>
    )
}
