/* eslint-disable @next/next/no-img-element */
import { Heading } from '../Heading'
import { useEffect, useState } from 'react'
import { CSSProperties } from 'react'

type ImageProps = {
    path?: string
    width?: string
    height?: string
    className?: string
    style?: CSSProperties
    alt: string
}

/**
 * Renders a fetched image based on the parameters.
 * @param path path to image
 * @param width an image width
 * @param height an image height
 * @param alt an alternative information of the image
 * @param className a style sheets class for the component
 * @param style inline CSS styles for the <img> tag
 */
const Image = ({
    path,
    width,
    height,
    alt,
    className = '',
    style,
}: ImageProps) => {
    return (
        <img
            width={width}
            height={height}
            src={path}
            alt={alt}
            className={className}
            style={style}
        />
    )
}

export default Image
