import {
    NavigationCardProps,
    NavigationCardVariant,
} from '@/components/NavigationCard/types'
import { NavigationCardMapRight } from '@/components/NavigationCard/UI/NavigationCardMapRight'
import { NavigationCardMapTop } from '@/components/NavigationCard/UI/NavigationCardMapTop'
import { NavigationCardMapLeft } from '@/components/NavigationCard/UI/NavigationCardMapLeft'

export const NavigationCard = ({
    variant = NavigationCardVariant.MAP_RIGHT,
    ...props
}: NavigationCardProps): JSX.Element => {
    const renderUIByVariant = () => {
        if (variant === NavigationCardVariant.MAP_LEFT) {
            return <NavigationCardMapLeft {...props} />
        } else if (variant === NavigationCardVariant.MAP_TOP) {
            return <NavigationCardMapTop {...props} />
        }
        return <NavigationCardMapRight {...props} />
    }

    return renderUIByVariant()
}
