'use client'

import FormComponents from '@/components/FormComponents'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { schema, ContactFormFields, solutionOpts } from './schema'
import { useCallback, useState } from 'react'
import { FormSubmitResponse } from '@/types/form'
import FormStatus from '@/components/FormComponents/FormStatus'

const { Checkbox, Select, Submit, TextArea, TextInput, Form } = FormComponents

export const FormBody = ({ formName }: { formName: string }) => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
        reset,
    } = useForm<ContactFormFields>({
        resolver: zodResolver(schema),
        mode: 'onChange',
    })
    const [formState, setFormState] = useState<FormSubmitResponse | null>(null)

    const onSubmit = useCallback(
        (data: ContactFormFields) => {
            setFormState(null)
            const form = new FormData()
            Object.entries(data).forEach(([key, value]) => {
                form.append(
                    key,
                    key === 'personal_data' ? 'gdpr' : (value as any)
                )
            })

            return fetch(`/api/contactForm/${formName}`, {
                method: 'POST',
                body: form,
            }).then((response) => {
                if (response.status >= 400) {
                    setFormState({
                        status: 'error',
                        msg: 'Server error, fill all fields and try again.',
                    })
                } else {
                    setFormState({
                        status: 'success',
                        msg: 'Successfully submitted.',
                    })
                    reset()
                }
            })
        },
        [reset, formName]
    )

    return (
        <Form
            action={`/api/contactForm/${formName}`}
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="flex w-[100%] gap-2">
                <TextInput
                    {...register('fname')}
                    error={errors?.fname?.message}
                    placeholder="First name"
                />
                <TextInput
                    {...register('lname')}
                    error={errors?.lname?.message}
                    placeholder="Last name"
                />
            </div>
            <div className="flex w-[100%] gap-2">
                <TextInput
                    {...register('email')}
                    error={errors?.email?.message}
                    placeholder="E-mail"
                    type="email"
                />
                <TextInput
                    {...register('tel')}
                    error={errors?.tel?.message}
                    placeholder="Phone number"
                    type="tel"
                />
            </div>

            <TextInput
                {...register('company')}
                error={errors?.company?.message}
                placeholder="Company"
            />
            <Controller
                name="solution"
                control={control}
                render={({ field: { onChange, ...rest } }) => (
                    <Select
                        {...rest}
                        placeholder="What types of services you are interested in"
                        required
                        options={solutionOpts}
                        onChange={(newValue) => {
                            onChange((newValue as any).value)
                        }}
                        error={errors?.solution?.message}
                    />
                )}
            />
            <TextArea
                {...register('message')}
                error={errors?.message?.message}
                placeholder="Message"
            />

            <Checkbox
                {...register('personal_data')}
                error={errors?.personal_data?.message}
                label="I agree to the processing of personal data"
                required
                url="https://www.inventi.cz/en/gdpr"
            />
            <div className="mt-1">
                <FormStatus state={formState} />
                {isSubmitting && <div className="mb-2">Loading...</div>}
                <Submit text="Send" />
            </div>
        </Form>
    )
}
