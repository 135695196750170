import { GradientBackgroundHeaderComponent } from '@/types/cmsGenerated'
import { GradientBackgroundHeader } from '@/components/GradientBackgroundHeader'

export default async function Component({
    settings,
}: GradientBackgroundHeaderComponent) {
    const { image, text, heading } = settings

    return (
        <GradientBackgroundHeader
            imagePath={image?.path}
            imageAlt={image?.title}
            text={text}
            heading={heading}
        />
    )
}
