import Table from '@/components/Table'
import { ComponentWrap } from '@/components/ComponentWrap'
import { TableComponent } from '@/types/cmsGenerated'

export default function Component({ settings }: TableComponent) {
    const { table_layout } = settings
    return (
        <ComponentWrap>
            <Table tableData={table_layout} />
        </ComponentWrap>
    )
}
