import { GridLayout } from './layout'
import { GridComponent } from '@/types/cmsGenerated'

export default function Grid({ columns, componentContext }: GridComponent) {
    const cols = columns || []
    return (
        <GridLayout
            columns={cols}
            componentContext={componentContext}
            columnsCount={cols.length}
        />
    )
}
