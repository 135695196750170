import { Container } from '@/components/Container'
import { renderLayout } from '@/cms_components'
import { ContainerComponent } from '@/types/cmsGenerated'

export default function Component({
    settings: {
        fluid,
        backgroundColor,
        spread,
        backgroundImage,
        bgOpacity,
        bgImagePosition,
        flow,
        justify,
        align,
        fullWidth,
        marginLeft,
        marginRight,
        marginTop,
        marginBottom,
    },
    children,
    componentContext,
}: ContainerComponent) {
    return (
        <Container
            fluid={fluid}
            backgroundColor={backgroundColor || undefined}
            spread={spread}
            backgroundImage={backgroundImage?.path}
            bgOpacity={bgOpacity}
            bgImagePosition={bgImagePosition || undefined}
            flow={flow || undefined}
            justify={justify || undefined}
            align={align || undefined}
            fullWidth={fullWidth}
            marginLeft={marginLeft || undefined}
            marginRight={marginRight || undefined}
            marginTop={marginTop || undefined}
            marginBottom={marginBottom || undefined}
        >
            {renderLayout(children, componentContext)}
        </Container>
    )
}
