import { ComponentWrap } from '@/components/ComponentWrap'
import { Divider, DividerColorkeys } from '@/components/Divider'
import { DividerComponent } from '@/types/cmsGenerated'

export default function Component({ settings }: DividerComponent) {
    const { width, type, direction, color, length } = settings

    const pickWidth = () => {
        if (type === 'double') {
            return 'thick'
        }
        return width || 'thin'
    }

    const makePercentageLength = () => {
        if (length === undefined || length === null) return undefined
        if (length > 100) {
            return 100
        } else if (length < 1) {
            return 1
        }
        return length
    }

    return (
        <ComponentWrap>
            <Divider
                width={pickWidth()}
                type={type || 'solid'}
                color={
                    (color as DividerColorkeys) || DividerColorkeys.BLUE_MEDIUM
                }
                direction={direction || 'horizontal'}
                percentageLength={makePercentageLength()}
            />
        </ComponentWrap>
    )
}
