import { FormSubmitResponse } from '@/types/form'
import clsx from 'clsx'

interface Props {
    state: FormSubmitResponse | null
}

export default function FormStatus({ state }: Props) {
    if (!state) {
        return null
    }

    return (
        <div
            className={clsx(
                'mb-2',
                state.status === 'success' ? 'text-green-700' : 'text-red-700'
            )}
        >
            {state.msg}
        </div>
    )
}
