import { ComponentWrap } from '@/components/ComponentWrap'
import { ContactFormComponent } from '@/types/cmsGenerated'
import { FormBody } from './formBody'

export default function Component({
    settings,
    componentContext,
}: ContactFormComponent) {
    const { FormName } = settings

    return (
        <ComponentWrap>
            <FormBody formName={FormName} />
        </ComponentWrap>
    )
}
