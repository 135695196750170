export enum DividerColorkeys {
    BLUE_MEDIUM = 'blue-medium',
    GRAY_LIGHT2 = 'gray-light2',
    BLUE_LIGHT = 'blue-light',
}

export const colorMap: Map<DividerColorkeys, string> = new Map([
    [DividerColorkeys.BLUE_MEDIUM, 'border-blue-medium'],
    [DividerColorkeys.GRAY_LIGHT2, 'border-gray-light2'],
    [DividerColorkeys.BLUE_LIGHT, 'border-blue-light'],
])

const getDividerType = (type: DividerProps['type']) => {
    if (type === 'dashed') return 'border-dashed'
    if (type === 'dotted') return 'border-dotted'
    if (type === 'double') return 'border-double'
    return ''
}

type DividerProps = {
    width: 'thin' | 'medium' | 'thick'
    type: 'dashed' | 'dotted' | 'double' | 'solid'
    direction?: 'horizontal' | 'vertical'
    percentageLength?: number
    color?: DividerColorkeys
    className?: string
}

export const Divider = ({
    width,
    type,
    color,
    percentageLength,
    direction = 'horizontal',
    className = '',
}: DividerProps) => {
    const getDividerWidth = (width: DividerProps['width']) => {
        if (width === 'thin') {
            if (direction === 'horizontal') {
                return 'border-b'
            } else {
                return 'border-r'
            }
        }
        if (width === 'medium') {
            if (direction === 'horizontal') {
                return 'border-b-2'
            } else {
                return 'border-r-2'
            }
        }
        if (width === 'thick') {
            if (direction === 'horizontal') {
                return 'border-b-4'
            } else {
                return 'border-r-4'
            }
        }
    }

    const borderWidth = getDividerWidth(width)
    const borderStyle = getDividerType(type)
    const colorStyle = color ? colorMap.get(color) : ''
    const marginStyle = direction === 'horizontal' ? 'my-6' : 'mx-6'
    const heightStyle = direction === 'vertical' ? 'h-full' : 'w-full'
    const makeLengthStyle = () => {
        if (!percentageLength) return undefined
        if (direction === 'horizontal') {
            return { width: `${percentageLength}%` }
        } else {
            return { height: `${percentageLength}%` }
        }
    }

    return (
        <div className={'flex justify-center items-center'}>
            <div
                className={`inline-block ${marginStyle} ${heightStyle} ${borderWidth} ${borderStyle} ${colorStyle} ${className}`}
                style={makeLengthStyle()}
            />
        </div>
    )
}
