import Image from '@/components/Image'
import { generateImagePath } from '@/utils/functions/helpers'

type Props = {
    imagePath: string | undefined
    imageAlt: string | undefined
    text: string
    heading: string
}

export const GradientBackgroundHeader = ({
    imagePath,
    imageAlt,
    text,
    heading,
}: Props) => {
    return (
        <div className="w-full pb-16">
            <div className="w-full bg-gradient-to-b from-white to-gray-light">
                <div className="text-2xl md:text-4xl px-4 lg:px-0 pb-8 md:pb-16 md:max-w-screen-xl md:mx-auto">
                    {heading}
                </div>
            </div>
            <div className="relative bg-gray-light -mb-64 h-64 -z-10 lg:h-96 lg:-mb-96 w-full" />
            <div className="md:max-w-screen-xl md:mx-auto">
                <div className="flex gap-8 flex-col md:flex-row">
                    <div className="w-full md:w-3/5 flex flex-col">
                        <Image
                            alt={imageAlt || ''}
                            path={generateImagePath(imagePath)}
                        />
                    </div>
                    <div className="w-full md:w-2/5 md:px-4 lg:px-20 flex flex-col items-start">
                        <div className="my-4 ml-16 md:ml-0 lg:my-16">
                            <Image path="/arrowDown.svg" alt="arrow" />
                        </div>
                        <div className="mb-8 lg:mb-16 px-4 md:px-0">{text}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
