type Props = {
    size: 'small' | 'medium' | 'large' | 'full'
    horizontal?: boolean
}

export const Spacer = ({ size, horizontal }: Props) => {
    const spacerSize = getSize(size, horizontal)
    return <div className={spacerSize} />
}

const getSize = (size: Props['size'], horizontal: Props['horizontal']) => {
    if (size === 'medium') return horizontal ? 'w-20' : 'h-20'
    if (size === 'large') return horizontal ? 'w-32' : 'h-32'
    if (size === 'full') return horizontal ? 'w-full' : 'h-full'
    return horizontal ? 'w-10' : 'h-10'
}
