import { Heading } from '@/components/Heading'
import { Button } from '@/components/Button'
import clsx from 'clsx'

const linkClasses = 'min-h-0 h-auto p-0'
const firstColumnClasses = 'w-44'
const secondColumnClasses = 'grow w-full'

export type ContactInfoProps = {
    address: string
    phoneText: string
    phoneForLink: string
    headingLabel?: string
    email?: string
    rootClasses?: string
    shippingHours?: string
    companyTaxId?: number
    companyVatId?: string
    variant?:
        | 'navigationCardMapLeft'
        | 'navigationCardMapRight'
        | 'navigationCardMapTop'
    addressLabel?: string
    shippingHoursLabel?: string
    contactInfoLabel?: string
    companyInfoLabel?: string
    phoneLabel?: string
    companyVatIdLabel?: string
    companyTaxIdLabel?: string
    emailLabel?: string
}

export const ContactInfo = ({
    address,
    shippingHours,
    phoneForLink,
    phoneText,
    email,
    companyTaxId,
    companyVatId,
    headingLabel = 'CONTACT',
    contactInfoLabel = 'Contact info',
    companyInfoLabel = 'Company info',
    addressLabel = 'Address',
    phoneLabel = 'Phone',
    shippingHoursLabel = 'Shipping hours',
    companyVatIdLabel = 'VAT',
    companyTaxIdLabel = 'ID number',
    emailLabel = 'E-mail',
    variant = 'navigationCardMapRight',
    rootClasses = '',
}: ContactInfoProps): JSX.Element => {
    const pickFirstHeadingTag = () => {
        if (variant === 'navigationCardMapTop') return 'h3'
        if (variant === 'navigationCardMapLeft') return 'h4'
        return 'h5'
    }

    const phoneHref = phoneForLink
        ? phoneForLink.trim().replace(/\s/gi, '').toLowerCase()
        : ''

    return (
        <div className={`flex w-full flex-col ${rootClasses}`}>
            <Heading tag={pickFirstHeadingTag()} text={headingLabel} />
            <div className={`flex flex-col mb-6 mt-6 gap-2.5`}>
                <div className={`flex`}>
                    <span className={firstColumnClasses}>{addressLabel}:</span>
                    <span className={secondColumnClasses}>{address}</span>
                </div>
                {shippingHours && (
                    <div className={`flex`}>
                        <span className={firstColumnClasses}>
                            {shippingHoursLabel}:
                        </span>
                        <span className={secondColumnClasses}>
                            {shippingHours}
                        </span>
                    </div>
                )}
            </div>
            <Heading
                tag={
                    variant === 'navigationCardMapLeft' ||
                    variant === 'navigationCardMapTop'
                        ? 'h5'
                        : 'h6'
                }
                text={contactInfoLabel}
                className="text-md font-bold"
            />
            <div
                className={clsx(`flex flex-col gap-2.5`, {
                    'mt-4':
                        variant === 'navigationCardMapLeft' ||
                        variant === 'navigationCardMapRight',
                    'mt-2.5': variant === 'navigationCardMapTop',
                })}
            >
                <div className={`flex`}>
                    <span className={firstColumnClasses}>{phoneLabel}:</span>
                    <div className={secondColumnClasses}>
                        <Button
                            url={`tel:${phoneHref}`}
                            color="link"
                            rootClasses={linkClasses}
                        >
                            {phoneText}
                        </Button>
                    </div>
                </div>
                {email && (
                    <div className={`flex`}>
                        <span className={firstColumnClasses}>
                            {emailLabel}:
                        </span>
                        <div className={secondColumnClasses}>
                            <Button
                                url={`mailto:${email}`}
                                color="link"
                                rootClasses={linkClasses}
                            >
                                {email}
                            </Button>
                        </div>
                    </div>
                )}
            </div>

            {(companyTaxId || companyVatId) && (
                <>
                    <Heading
                        tag={
                            variant === 'navigationCardMapLeft' ||
                            variant === 'navigationCardMapTop'
                                ? 'h5'
                                : 'h6'
                        }
                        text={companyInfoLabel}
                        className="text-md font-bold mt-6"
                    />
                    <div
                        className={clsx(`flex flex-col gap-2.5`, {
                            'mt-4':
                                variant === 'navigationCardMapLeft' ||
                                variant === 'navigationCardMapRight',
                            'mt-3': variant === 'navigationCardMapTop',
                        })}
                    >
                        {companyTaxId && (
                            <div className={`flex`}>
                                <span className={firstColumnClasses}>
                                    {companyTaxIdLabel}:
                                </span>
                                <div className={secondColumnClasses}>
                                    {companyTaxId}
                                </div>
                            </div>
                        )}
                        {companyVatId && (
                            <div className={`flex`}>
                                <span className={firstColumnClasses}>
                                    {companyVatIdLabel}:
                                </span>
                                <div className={secondColumnClasses}>
                                    {companyVatId}
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}
