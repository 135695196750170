import { ComponentWrap } from '@/components/ComponentWrap'
import { HtmlComponent } from '@/types/cmsGenerated'

export default function Component({
    settings,
    componentContext,
}: HtmlComponent) {
    const { html, color, align } = settings

    const cmsStyles = {
        color: !!color ? color : '#000000',
        textAlign: !!align ? align : 'left',
    }

    return (
        <ComponentWrap>
            <div
                style={cmsStyles}
                dangerouslySetInnerHTML={{ __html: html || '' }}
            />
        </ComponentWrap>
    )
}
