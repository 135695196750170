'use client'
import { toast } from 'react-toastify'
import { FormComponent } from '@/types/cmsGenerated'
import { generateImagePath } from '@/utils/functions/helpers'
import Image from '@/components/Image'
import Form from '@/components/FormComponents/form'
import {
    FormEventHandler,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { TextInput } from '@/components/FormComponents/textInput'
import { TextArea } from '@/components/FormComponents/textArea'
import { Select } from '@/components/FormComponents/select'
import { Checkbox } from '@/components/FormComponents/checkbox'
import { Button } from '@/components/Button'
import { SelectOption } from '@/components/FormComponents/types'
import { FieldConfig } from '@/types/cmsTypesGeneration/getFieldType'
import { CmsContext } from '@/components/CmsProvider'

export default function Component({ settings }: FormComponent) {
    const {
        logo,
        title,
        subtitle,
        showAgreement,
        agreementText,
        submitText,
        targetCollection,
        initValues,
    } = settings
    const [collectionDefinition, setCollectionDefinition] = useState<{
        fields: FieldConfig[]
    }>({ fields: [] })
    const [formValues, setFormValues] = useState(initValues)
    const { lang } = useContext(CmsContext)

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(
                `/api/collection?collectionName=${targetCollection}`
            )
            const result = await response.json()

            setCollectionDefinition(result)
        }

        fetchData()
    }, [targetCollection])

    const hasLanguageCode = useMemo(() => {
        if (!collectionDefinition?.fields) {
            return false
        }
        return collectionDefinition?.fields.some(
            (f) => f.name === 'language_code'
        )
    }, [collectionDefinition?.fields])

    const handleInputCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target
        setFormValues({ ...formValues, [name]: checked })
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormValues({ ...formValues, [name]: value })
    }

    const handleSelectChange = (name?: string) => (choice: unknown) => {
        if (name !== undefined) {
            setFormValues({
                ...formValues,
                [name]: (choice as SelectOption).value,
            })
        }
    }

    const handleSubmit: FormEventHandler = useCallback(
        (e) => {
            e.preventDefault()
            if (showAgreement && !formValues.agree) {
                toast.error('You must agree with the policy.')
                return
            }

            const { agree: _, ...body } = formValues
            if (hasLanguageCode) {
                body.language_code = lang
            }
            const saveData = async () => {
                const response = await fetch(
                    `/api/collection?collectionName=${targetCollection}`,
                    {
                        method: 'POST',
                        body: JSON.stringify(body),
                    }
                )

                if (response.ok) {
                    toast.success('Form send successfully.')
                    setTimeout(() => window.location.reload(), 2000)
                }
            }

            saveData()
        },
        [formValues, showAgreement, targetCollection, lang, hasLanguageCode]
    )

    return (
        <div className="mx-0 md:mx-4 my-8">
            {logo && (
                <Image
                    path={generateImagePath(logo?.path) || undefined}
                    alt={logo?.title || ''}
                    className="max-h-10"
                />
            )}
            {title && <h3 className="mt-8 mb-4">{title}</h3>}
            {subtitle && (
                <div className="body3 text-blue-middle">{subtitle}</div>
            )}
            <Form
                className="flex flex-col items-start my-8 !p-0"
                onSubmit={handleSubmit}
            >
                {collectionDefinition?.fields?.map((field, index) => {
                    console.log(field)
                    const type = field?.type
                    const required = !!field?.required
                    if (!type || !!field.options.hideInForm) {
                        return null
                    }

                    if (type === 'text' || type === 'email') {
                        return (
                            <TextInput
                                key={field?.name + index}
                                required={required}
                                className="border-1 border-gray-light2 rounded-lg py-4 text-blue-dark placeholder:text-blue-middle"
                                type={type}
                                name={field?.name}
                                value={formValues[field?.name] || ''}
                                onChange={handleInputChange}
                                placeholder={
                                    field?.label + (required ? ' *' : '')
                                }
                            />
                        )
                    }

                    if (type === 'textarea') {
                        return (
                            <TextArea
                                key={field?.name + index}
                                required={required}
                                className="border-1 border-gray-light2 rounded-lg py-4 text-blue-dark placeholder:text-blue-middle"
                                name={field?.name}
                                value={formValues[field?.name] || ''}
                                onChange={handleInputChange}
                                placeholder={
                                    field?.label + (required ? ' *' : '')
                                }
                            />
                        )
                    }

                    if (type === 'select') {
                        return (
                            <Select
                                key={field?.name + index}
                                defaultValue={
                                    formValues[field?.name]
                                        ? {
                                              value: formValues[field?.name],
                                              label: formValues[field?.name],
                                          }
                                        : undefined
                                }
                                required={required}
                                name={field?.name}
                                label={field?.label + (required ? ' *' : '')}
                                options={field?.options.options
                                    .split(', ')
                                    .map((option: string) => ({
                                        label: option,
                                        value: option,
                                    }))}
                                value={formValues[field?.name] || ''}
                                onChange={handleSelectChange(field?.name)}
                                labelInside
                            />
                        )
                    }
                })}
                {showAgreement && (
                    <div className="flex mt-4">
                        <Checkbox
                            name="agree"
                            onChange={handleInputCheckChange}
                            label=""
                        />
                        <div
                            className="ml-4 text-blue-middle"
                            dangerouslySetInnerHTML={{ __html: agreementText }}
                        />
                    </div>
                )}
                <Button
                    rootClasses="w-56 mt-8"
                    buttonType="submit"
                    color="secondary"
                >
                    {submitText}
                </Button>
            </Form>
        </div>
    )
}
