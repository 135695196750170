import { Info_box_placeholderComponent } from '@/types/cmsGenerated'
import { getInfoBoxById } from '@/api/api'
import { renderLayout } from '@/cms_components'

export default async function Component({
    settings,
    componentContext,
}: Info_box_placeholderComponent) {
    const { id } = settings
    const result = await getInfoBoxById(id, componentContext.lang)

    return renderLayout(result.data.layout, {
        ...componentContext,
        collectionSingletonName: result.collectionName,
        collectionId: result.data._id,
    })
}
